<template>
  <div class="eye-container" :style="props.isEye ? { flex: 1 } : {}">
    <slot />
    <div v-if="isEye" style="cursor: pointer" @click="handleEye">
      <svg-icon :icon-class="icon"></svg-icon>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, computed } from 'vue'
  import useUserStore from '@/store/user'
  import { IExtendObj } from '../../types'
  import { saveTrackEvent } from '@/api/sc'

  const userStore = useUserStore()

  interface IProps {
    type: string
    title?: string
    params: IExtendObj
    isEye?: boolean
  }

  /**
   * 自定义事件
   */
  interface IYfEmits {
    (e: 'handleChangeEye', params: string): void
  }

  const props = defineProps<IProps>()
  const emit = defineEmits<IYfEmits>()

  const type = ref<string>('close')
  const icon = computed({
    get: () => (type.value === 'close' ? 'eye_hide' : 'eye_open'),
    set: () => {}
  })
  const isOpen = ref<boolean>(false)

  const handleEye = () => {
    console.log('type', type.value)
    if (type.value === 'close') {
      icon.value = 'eye_open'
      type.value = 'open'
      if (!isOpen.value) {
        handleViewDesensitization()
      }
      isOpen.value = true
    } else {
      icon.value = 'eye_hide'
      type.value = 'close'
    }
    console.log('type', type.value)
    emit('handleChangeEye', type.value)
  }

  const handleViewDesensitization = async () => {
    try {
      await saveTrackEvent({ jsonData: { ...props.params, applicationName: 'pc云采订货系统' } })
    } catch (e) {
      console.log(e)
    }
  }
</script>
<style lang="scss" scoped>
  .eye-container {
    display: flex;
    //align-items: center;
    .svg-icon {
      font-size: 16px;
      margin-left: 4px;
    }
  }
</style>
