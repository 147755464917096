import { getSignature } from '@/api/sc'
import moment from 'moment'
import OSS from 'ali-oss'
interface OSSExtends extends OSS {
  stsTokenFreshTime?: Date
}
let policyRes: any = null
export default class Signature {
  isPublic: boolean
  client: Promise<OSSExtends>
  static instance: Signature
  constructor(isPublic: boolean) {
    const client = this.init()
    this.isPublic = isPublic
    this.client = client
  }

  static getInstance(isPublic: boolean) {
    // 单例
    Signature.instance = new Signature(isPublic)
    // if (!Signature.instance) {
    //   Signature.instance = new Signature()
    // }
    return Signature.instance
  }

  // 10s清空
  async init() {
    if (!policyRes) {
      policyRes = getSignature({ timestamp: moment().valueOf() + Math.random() })
      setTimeout(() => {
        policyRes = null
      }, 10000)
    }
    const { content: res } = await policyRes

    const client: OSSExtends = new OSS({
      region: 'oss-cn-shanghai',
      accessKeyId: res.accessKeyId,
      accessKeySecret: res.accessKeySecret,
      stsToken: res.securityToken,
      bucket: this.isPublic ? res.publicReadBucketName : res.bucketName,
      refreshSTSToken: async () => {
        if (!policyRes) {
          policyRes = getSignature({ timestamp: moment().valueOf() + Math.random() })
          setTimeout(() => {
            policyRes = null
          }, 10000)
        }
        const { content: info } = await policyRes
        return {
          accessKeyId: info.accessKeyId,
          accessKeySecret: info.accessKeySecret,
          stsToken: info.securityToken
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 10000,
      // stsTokenFreshTime: new Date(),
      endpoint: 'https://oss-cn-shanghai.aliyuncs.com'
    })
    client.stsTokenFreshTime = new Date()
    return client
  }

  get() {
    return this.client
  }
}
