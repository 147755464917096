import useUserStore from '@/store/user'
import { IExtendObj } from '../types'
export default function useNotLogin(loginFunc: () => void, notLoginReturnData: IExtendObj) {
  const userStore = useUserStore()
  if (!userStore.user?.token) {
    return {
      code: '000',
      ...notLoginReturnData
    }
  } else {
    return loginFunc()
  }
}
