<template>
  <div :class="['circle', 'log-' + props.type]">
    <div :class="['mini-circle', 'log-' + props.type]">
      <div class="title">{{ props.value }}</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, watch } from 'vue'
  import type { IExtendObj } from '@/types'

  interface IProps {
    type: string
    value: string
  }
  const props = defineProps<IProps>()
</script>
<style lang="scss" scoped>
  $success: #45c99c;
  $warning: #ff7309;
  $info: #aaaaaa;
  $danger: #ff1a24;
  .log-success {
    color: $success;
    border-color: $success;
  }

  .log-warning {
    color: $warning;
    border-color: $warning;
  }

  .log-info {
    color: $info;
    border-color: $info;
  }

  .log-danger {
    color: $danger;
    border-color: $danger;
  }

  .circle {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: 5px solid;
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mini-circle {
      width: 80%;
      height: 80%;
      border: 2px dotted;
      box-sizing: content-box;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transform: rotate(-30deg);

      .mini-title {
        font-size: 12px;
        transform: scale(0.8);
      }

      .title {
        font-size: 16px;
        background-color: #fff;
        min-width: 100%;
        text-align: center;
        border: 2px solid #ffffff;
        box-sizing: content-box;
        padding: 4px 0;
        white-space: nowrap;
      }

      .meduim-title {
        font-size: 12px;
      }
    }
  }
</style>
