<template>
  <div class="login-content_wrap">
    <div class="login-content_wrap-title">
      <div
        class="header-phone"
        :class="type === 'phone' ? 'active-header' : ''"
        @click="handlePhone"
        >手机号登录
      </div>
      <div class="header-user" :class="type === 'user' ? 'active-header' : ''" @click="handleUser"
        >账户登录
      </div>
    </div>
    <div class="login-content_wrap-content">
      <div v-if="errorMsg" class="error">
        <el-icon>
          <WarningFilled />
        </el-icon>
        {{ errorMsg }}
      </div>
      <el-form v-if="type === 'user'" :model="form" ref="loginFormRef" label-width="0px">
        <el-form-item label="">
          <el-input v-model.trim="form.name" placeholder="请输入账号" size="large">
            <template #prepend>
              <div class="prepend">
                <svg-icon icon-class="user" size="20px" />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            ref="password"
            v-model="form.password"
            :type="passwordType"
            placeholder="请输入密码"
            size="large"
          >
            <template #prepend>
              <div class="prepend">
                <svg-icon icon-class="pwd" size="20px" />
              </div>
            </template>
            <template #suffix>
              <div class="eye" @click="showPwd">
                <svg-icon
                  :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
                  size="16px"
                />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="check">
            <el-checkbox v-model="checked"></el-checkbox>
            <span class="text"
              >我已认真阅读、理解并同意：<a @click="handleDialog(1)">《平台服务协议》</a>、<a
                @click="handleDialog(2)"
                >《隐私政策》</a
              >、<a @click="handleDialog(3)">《平台注册协议》</a></span
            >
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="btn" @click="handleSubmit(loginFormRef)">登录</el-button>
        </el-form-item>
      </el-form>
      <el-form v-if="type === 'phone'" :model="phoneForm" ref="loginPhoneFormRef" label-width="0px">
        <el-form-item label="">
          <el-input v-model.trim="phoneForm.phone" placeholder="请输入手机号" size="large">
            <template #prepend>
              <div class="prepend">
                <svg-icon icon-class="phone" size="20px" />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="" class="code-wrap">
          <el-input
            v-model="phoneForm.smsCode"
            class="code"
            placeholder="请输入验证码"
            size="large"
          >
            <template #prepend>
              <div class="prepend">
                <svg-icon icon-class="code" size="20px" />
              </div>
            </template>
          </el-input>
          <el-button
            class="send-code"
            @click="handleSendSms"
            size="large"
            :disabled="countDown.ss !== '00'"
            >发送验证码{{ countDown.ss !== '00' ? `(${countDown.ss})` : '' }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <div class="check">
            <el-checkbox v-model="checked"></el-checkbox>
            <span class="text"
              >我已认真阅读、理解并同意：<a @click="handleDialog(1)">《平台服务协议》</a>、<a
                @click="handleDialog(2)"
                >《隐私政策》</a
              >、<a @click="handleDialog(3)">《平台注册协议》</a></span
            >
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="btn" @click="handlePhoneSubmit(loginPhoneFormRef)"
            >登录/注册
          </el-button>
        </el-form-item>
      </el-form>
      <div class="notice-login">
        <div v-auth:menu="'register'">
          <template v-if="type === 'phone'"> 若手机号未注册登录自动创建账号 </template>
          <template v-else>
            没有账号？点击<span class="register-btn" @click="handleRegister">注册</span>
          </template>
        </div>
        <div v-if="type === 'user'" class="forget-pwd" @click="handleForget">忘记密码</div>
      </div>
    </div>
  </div>
  <agreement-and-privacy-dialog ref="agreementAndPrivacyDialog" />
</template>

<script setup lang="ts">
  import { nextTick, reactive, ref } from 'vue'
  import { FormInstance, ElMessage, ElMessageBox } from 'element-plus'
  import { regSendSms } from '@/api/sc'
  import { select_contract_page } from '@/api/cus'
  import router from '@/router/index'
  import useUserStore from '@/store/user'
  import systemDictStroe from '@/store/dict'
  import useCountDown from '@/hooks/useCountDown'
  import useCustomerStore from '@/store/customer'
  import useMenuStore from '@/store/menu'
  import AgreementAndPrivacyDialog from '@/components/agreementAndPrivacyDialog/index.vue'
  import useShopStore from '@/store/store'
  import useAdvertisingStore from '@/store/advertising'

  import { cloneDeep } from 'lodash'
  import { authValidate } from '@/store/auth'
  import { ShowSignSupplementaryAgreement } from '@/components/SignSupplementaryAgreement/signSupplementaryAgreement'
  interface IProps {
    isOpenDialog: boolean
  }

  const props = withDefaults(defineProps<IProps>(), {
    isOpenDialog: false
  })

  interface ILoginComponentEmits {
    (e: 'login-success', isNewUser: boolean): void
  }

  const emit = defineEmits<ILoginComponentEmits>()
  const useShop = useShopStore()

  const userStore = useUserStore()
  const systemDict = systemDictStroe()
  const loginFormRef = ref<FormInstance>()
  const loginPhoneFormRef = ref<FormInstance>()
  const useCustomer = useCustomerStore()
  const menuStore = useMenuStore()

  const passwordType = ref<string>('password')
  const password = ref<any>()

  const showPwd = () => {
    if (passwordType.value === 'password') {
      passwordType.value = ''
    } else {
      passwordType.value = 'password'
    }
    nextTick(() => {
      password.value.focus()
    })
  }

  const form = reactive({
    name: '',
    password: ''
  })
  const phoneForm = reactive({
    phone: '',
    smsCode: ''
  })
  const checked = ref<boolean>(false)
  const errorMsg = ref<string>('')

  const type = ref<string>('phone')
  const handleUser = () => {
    type.value = 'user'
    checked.value = false
  }
  const handlePhone = () => {
    type.value = 'phone'
    checked.value = false
  }

  const routerRules = async () => {
    if (!userStore.user?.token) return
    if (useShop.store?.currentStore && Number(useShop.store?.currentStore.storeType) === 1) {
      emit('login-success', isNewUser)

      // 重新计算 广告次数
      const advertisingStore = useAdvertisingStore()
      advertisingStore.resetAdvertising()
    } else {
      // 如果当前为自营店铺则按照之前的逻辑进行
      if (Number(useCustomer.customer?.isActivation) === 1) {
        console.log('router')
        if (
          Number(useCustomer.customer?.authenticationStatus) === 2 &&
          Number(useCustomer.customer?.signContractStatus) === 2
        ) {
          // 如果客户已签署合同、已认证的情况下存在合同补充协议 需要弹框提示并获取合同列表数据
          if (
            useCustomer.customer?.notSignContractCount &&
            menuStore.buttonCodeList.includes('account:supplementaryAgreement')
          ) {
            const { content: contactList } = await select_contract_page({
              jsonData: { size: 9999, current: 1, status: 1 }
            })
            useCustomer.customer.notSignContractList = contactList.records
            useCustomer.updateCustomerInfo({
              ...useCustomer.customer,
              notSignContractList: contactList.records
            })
            await ShowSignSupplementaryAgreement()
          }
        }
        // 已激活
        emit('login-success', isNewUser)
      } else {
        // 未激活
        if (useCustomer.customer?.approvalStatus === '2') {
          //已审核
          emit('login-success', isNewUser)
          //如果是弹框打开，需要通知弹框关闭
          if (props.isOpenDialog) {
            emit('login-success', false)
          }
        } else {
          router.replace({
            name: 'Register',
            query: {
              type: Number(userStore.user?.customerType) === 1 ? 'user' : 'company',
              active: Number(useCustomer.customer?.approvalStatus) === 1 ? 3 : 2
            }
          })
          // emit('login-success', false)
          // await authValidate()
        }
      }
    }
    await authValidate()
  }
  let isNewUser = false
  const handleSubmit = async (formEl: FormInstance | undefined) => {
    if (!formEl) return
    if (!form.name) return (errorMsg.value = '请输入账号')
    if (!form.password) return (errorMsg.value = '请输入密码')
    errorMsg.value = ''
    try {
      if (!checked.value) {
        // await ElMessageBox.alert('请同意平台服务协议、隐私协议！', '', {
        //   confirmButtonText: '确定',
        //   type: 'warning'
        // })
        // checked.value = true
        ElMessage.error('请先阅读并同意相关协议！')
        return
      }
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const oldUser = cloneDeep(userStore.user)
          await userStore.login(form.name, form.password)
          const newUser = userStore.user
          if (props.isOpenDialog) {
            isNewUser = !oldUser || oldUser.userId !== newUser.userId
          }
          // 授权店铺判断
          // await storeValidate()
          const isSelectStore = !props.isOpenDialog || (props.isOpenDialog && isNewUser)
          if (isSelectStore) {
            await useShopStore().getShop()
          }
          await useCustomer.getCustomerInfo('Login', isSelectStore)
          await systemDict.getDict()
          // 进入路由判断
          await routerRules()
        } else {
          console.log('error submit!', fields)
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  const handlePhoneSubmit = async (formEl: FormInstance | undefined) => {
    if (!formEl) return
    if (!phoneForm.phone) return (errorMsg.value = '请输入手机号')
    if (!phoneForm.smsCode) return (errorMsg.value = '请输入验证码')
    errorMsg.value = ''
    try {
      if (!checked.value) {
        // await ElMessageBox.alert('请勾选：已阅读并同意平台服务协议及隐私政策！', '', {
        //   confirmButtonText: '确定',
        //   type: 'warning'
        // })
        ElMessage.error('请先阅读并同意相关协议！')
        return
      }
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const oldUser = cloneDeep(userStore.user)
          await userStore.phoneLogin(phoneForm.phone, phoneForm.smsCode)

          const newUser = userStore.user
          if (props.isOpenDialog) {
            isNewUser = oldUser.userId !== newUser.userId
          }
          // 授权店铺判断
          // await storeValidate()
          const isSelectStore = !props.isOpenDialog || (props.isOpenDialog && isNewUser)
          // 授权店铺判断
          if (isSelectStore) {
            await useShopStore().getShop()
          }
          // await storeValidate()
          await useCustomer.getCustomerInfo('Login', isSelectStore)
          await systemDict.getDict()
          routerRules()
        } else {
          console.log('error submit!', fields)
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  let countDownMs = { leftTime: ref(0) }
  let { countDown } = useCountDown(countDownMs, () => {
    countDownMs.leftTime.value = 0
  })

  async function handleSendSms() {
    try {
      if (!phoneForm.phone) {
        ElMessage.error('请先输入手机号')
        return
      }
      await regSendSms(phoneForm.phone)
      countDownMs.leftTime.value = 60000
    } catch (error) {}
  }

  const handleRegister = () => {
    userStore.removeUser()
    type.value = 'phone'
    // router.replace({ path: '/register' })
  }

  // 协议
  const agreementAndPrivacyDialog = ref<any>()
  const handleDialog = (val: number) => {
    agreementAndPrivacyDialog.value.open(val)
  }
  const handleForget = () => {
    router.replace({ name: 'ForgetPwd' })
  }
</script>

<style lang="scss" scoped>
  .login {
    // &-container {
    //   min-width: 1366px;
    // }
    // &-top {
    //   width: 100%;
    //   background-color: #fff;
    //   height: 110px;
    //   line-height: 110px;
    //   padding: 22px 0;
    //   .logo {
    //     width: 280px;
    //     height: auto;
    //     margin-left: 210px;
    //   }
    // }
    &-content {
      // position: relative;
      // display: flex;
      // justify-content: flex-end;
      // align-content: center;
      // width: 100%;
      // height: 492px;
      // padding: 0 140px;
      // background-image: url('../../assets/login/bg.png');
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: 100% auto;
      &_wrap {
        margin-top: 50px;
        background-color: #fff;
        width: 360px;
        height: 400px;
        border-radius: 4px;
        box-shadow: 0 0 15px rgb(204 204 204 / 50%);

        &-title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 360px;
          height: 60px;
          text-align: center;
          font-size: 16px;
          border-bottom: 1px solid rgb(232, 232, 232);
          margin-bottom: 30px;
          cursor: pointer;
          color: rgb(39, 39, 39);

          .header-user {
            width: 50%;

            border-right: 1px solid rgb(232, 232, 232);
          }

          .header-phone {
            width: 50%;
          }

          .active-header {
            color: rgb(227, 69, 75);
          }
        }

        &-content {
          position: relative;
          padding: 0 30px;

          .error {
            background-color: #fef2f2;
            margin-bottom: 10px;
            margin-top: -20px;
            padding: 3px;
            border: 1px solid #ffb4a8;
            display: flex;
            align-items: center;
            color: #6c6c6c;

            :deep(.el-icon) {
              color: #ff934c;
              margin-right: 5px;
            }
          }

          ::v-deep(.el-input-group__prepend) {
            padding: 0 10px;
          }

          .prepend {
            line-height: 4px;
          }

          .eye {
            cursor: pointer;
          }

          .btn {
            background-color: rgb(226, 60, 67);
            border: transparent;
            width: 100%;
            height: 40px;
          }

          .code-wrap {
            :deep(.el-form-item__content) {
              display: flex;
              justify-content: space-between;
            }
          }

          .code {
            width: calc(65% - 10px);
          }

          .send-code {
            width: calc(35%);
          }

          .check {
            display: flex;

            :deep(.el-checkbox) {
              margin-right: 5px;
            }

            .text {
              margin-left: 5px;
            }

            a {
              color: rgb(227, 69, 75);
            }
          }
        }
      }

      .notice-login {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgb(135, 135, 135);
        font-size: 14px;

        .register-btn {
          cursor: pointer;
          color: rgb(226, 60, 67);
        }

        .forget-pwd {
          cursor: pointer;
        }
      }
    }

    &-footer {
      margin-top: 20px;
      padding: 27px 0;
      width: 100%;
    }
  }
</style>
