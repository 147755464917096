import { post, get } from '@/utils/request'
import { IExtendObj } from '@/types'
import useNotLogin from '@/hooks/useNotLogin'

const gate = '/api/ps'

export function getOrderSku(data: IExtendObj) {
  return post<any>(`${gate}/sku/v1/select_order_sku_page`, data)
}

/**
 * 快速订货单商品
 * @param data
 * @returns
 */
export function getSubscribeSpu(data: IExtendObj) {
  return post<any>(`${gate}/min_app/v1/select_spu_page`, data)
}

/**
 * 快速订货单-品牌
 * @param data
 * @returns
 */
export function getSubscribeBrand(data: IExtendObj) {
  return post<any>(`${gate}/spu/v1/select_brand_subscribe_list`, data)
}

/**
 * 快速订货单-国家
 * @param data
 * @returns
 */
export function getSubscribeCountry(data: IExtendObj) {
  return post<any>(`${gate}/spu/v1/select_country_subscribe_list`, data)
}

/**
 * 查询店铺详情
 * @param data
 * @returns
 */
export function getStoreDetail(data: IExtendObj) {
  return post<any>(`${gate}/store/v1/query_store_detail`, data)
}

/**
 * 根据子级查询上级类目
 */
export function getChildCategory(data: IExtendObj) {
  return get<any>(
    `${gate}/ps_category/v1/select_parent_category_list?status=2&childId=${data.childId}`,
    {}
  )
}

/**
 * 类目列表
 */
export function queryCategory(data: IExtendObj) {
  return post<any>(`${gate}/ps_category/v1/select_all_category_for_lite_app`, data)
}

/**
 * 获取商品详情所选的商品列表数据
 */
export function getSkuList(data: IExtendObj) {
  return post<any>(`${gate}/spu/v1/select_sku_list_by_id`, data)
}

/**
 * 商品详情
 */
export function getProDetail(data: IExtendObj) {
  return post<any>(`${gate}/spu/v1/spu_details`, data)
}

/**
 * 查询商品的末级类目
 */
export function getProCategory(data: IExtendObj) {
  return post<any>(`${gate}/spu/v1/select_category_list`, data)
}

/**
 * 根据skuIdList获取商品信息
 */
export function selectAllCategoryForLiteApp(data: IExtendObj) {
  return post<any>(`${gate}/ps_category/v1/select_all_category_for_lite_app`, data)
}

/**
 * 根据skuIdList获取商品信息
 */
export function selectGoodsBySkuList(data: IExtendObj) {
  return post<any>(`${gate}/min_app/v1/select_sku_page`, data)
}
/**
 * 快速进货卡片列表
 */
export function getCardSkuList(data: IExtendObj) {
  return post<any>(`${gate}/min_app/v1/select_sku_page`, data)
}
/**
 * 支付方式列表
 */
export function selectStorePayType(data: IExtendObj) {
  return post<any>(`${gate}/store/v1/select_store_pay_type`, data)
}

/**
 * 收藏商品
 */
export function getCollect(data: IExtendObj) {
  return post<any>(`${gate}/ps_favorite/v1/save`, data)
}

/**
 * 批量取消收藏商品
 */
export function batchCancelCollect(data: IExtendObj) {
  return post<any>(`${gate}/ps_favorite/v1/batch_cancel`, data)
}

/*
 * 最近搜索记录列表
 */
export function getSearchRecods() {
  return post<any>(`${gate}/spu/v1/get_search_records`)
}

/**
 * 查找品牌详情
 */
export function queryBrandDetail(data: IExtendObj) {
  return post<any>(`${gate}/brand/v1/query_brand_detail`, data)
}

// 关注品牌
export function storeBrandFocus(data: IExtendObj) {
  return post<any>(`${gate}/store_brand_focus/v1/focus`, data)
}

// 取消关注品牌
export function storeBrandCancelFocus(data: IExtendObj) {
  return post<any>(`${gate}/store_brand_focus/v1/cancel_focus`, data)
}

// 查找品牌关注列表
export function storeBrandFocusQuery(data: IExtendObj) {
  return post<any>(`${gate}/store_brand_focus/v1/query`, data)
}

// 查找品牌关注首字母
export function getFirstLetterForBrand(data: IExtendObj) {
  return post<any>(`${gate}/brand/v1/get_first_letter`, data)
}

// 查找品牌关注国家
export function getCountryForBrand(data: IExtendObj) {
  return post<any>(`${gate}/brand/v1/get_country`, data)
}

// 查找 品牌
export function queryBrandPageForPc(data: IExtendObj) {
  return post<any>(`${gate}/brand/v1/query_brand_page_for_pc`, data)
}

// 查找 推荐品牌
export function queryBrandRecommandForClient(data: IExtendObj) {
  return post<any>(`${gate}/store_brand_recommend/v1/query_for_client`, data)
}

/**
 * 清空搜索记录
 */
export function clearSearchHistory(data: IExtendObj) {
  return post<any>(`${gate}/spu/v1/delete_search_records`, data)
}

/**
 * 快速筛选
 */
export function getSubscribeSelectList(data: IExtendObj) {
  return post<any>(`${gate}/min_app/v1/select_sku_list`, data)
}

export function store_brand_focus(data: IExtendObj) {
  return post<any>(`${gate}/store_brand_focus/v1/query_page`, data)
}

// 获取品牌负责人信息
export function query_brand_director(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/brand/v1/query_brand_director`, data)
}

/**
 * 到货通知
 */
export function arrival_notice(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/arrival_notification/v1/save`, data)
}

/**
 * 查询店铺物流公司
 */
export function storeLogisticsCompany(data: IExtendObj) {
  return post<IExtendObj[]>(`${gate}/store/v1/logistics_company`, data)
}

/**
 * 获取登录人手机号
 */
export function get_user_phone(data?: IExtendObj) {
  return useNotLogin(
    () => post<any>(`${gate}/arrival_notification/v1/query_login_user_phone`, data),
    {
      content: null
    }
  )
}

/**
 * 品牌列表
 */
export function get_brand_list(data: IExtendObj) {
  return get<IExtendObj[]>(
    `${gate}/brand/v1/query_brand_list?status=2&isVirtualBrand=0&isSort=1&customerId=${data.customerId}&channelId=${data.channelId}&psStoreId=${data.psStoreId}`,
    {}
  )
}
