import { createRouter, createWebHistory } from 'vue-router'
import login from './modules/login'
import useUserStore from '@/store/user'
import useMenuStore from '@/store/menu'
import { authValidate } from '@/store/auth'
import { ElNotification } from 'element-plus'
import { ShowLoginDialog } from '@/components/LoginComponent/LoginDialogModel'
import useShopStore from '@/store/store'
import useCustomerStore from '@/store/customer'

const routes = [...login]
const whiteList = [
  '/login',
  '/test',
  '/register',
  '/verifyResult',
  '/contractResult',
  '/404',
  '/forgetPwd',
  '/homePage',
  '/select-decorate',
  '/redirect'
  // '/stock/fast',
  // '/detail/index'
] // 路由白名单
const router = createRouter({
  history: createWebHistory(),
  // // 每次切换路由的时候滚动到页面顶部
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes
})
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const useMenu = useMenuStore()
  const useShop = useShopStore()
  const useCustomer = useCustomerStore()
  if (router.getRoutes().length === login.length) {
    await useMenu.handleMenu()
    //未登录情况下先请求默认店铺
    if (!userStore.user?.token) {
      await useShop.getShop(false)
    }
    next({ ...to, replace: true })
    return
  }
  if (whiteList.includes(to.path) || to.path.includes('redirect')) {
    next()
    return
  }
  if (to.path === '/') {
    // next({ path: '/stock/fast', query: { redirect: to.fullPath } })
    next({ path: '/homePage', query: { redirect: to.fullPath } })
    return
  }

  if (
    ![
      '/',
      '/login',
      '/stock/fast',
      '/detail/index',
      '/account/index',
      '/register',
      '/guide',
      '/contract',
      '/verifyResult',
      '/contractResult',
      '/category/categoryCenter',
      '/brand/brandCenter',
      '/brand/brandDetail'
    ].includes(to.path) &&
    userStore.user?.token
  ) {
    if (await authValidate()) {
      next({ path: '/homePage' })
      return
    }
  }

  if (
    !useMenu.routerList.map((item: any) => item.name).includes(to.name) &&
    !login.map((item: any) => item.name).includes(to.name)
  ) {
    ElNotification.error({
      title: '暂无权限',
      message: '对不起，您暂时没有相关的操作权限！如须操作，请联系业务员！'
    })
    next({ ...from, replace: true })
    return
  }
  if (!userStore.user?.token && !to.meta.isNotLogin) {
    // next({ name: 'Login', query: { redirect: to.fullPath } })
    ShowLoginDialog()
    return
  }
  // 登录但账号未审核的状态下只能进入注册页面
  if (userStore.user?.token && useCustomer.customer?.approvalStatus !== '2') {
    next({
      path: '/register',
      query: {
        type: Number(userStore.user?.customerType) === 1 ? 'user' : 'company',
        active: Number(useCustomer.customer?.approvalStatus) === 1 ? 3 : 2
      }
    })
    return
  }
  next()
})
router.onError((error) => {
  console.log(error, 'error')
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    console.error(error.message)
    router.replace(targetPath)
  }
  window.location.reload()
})
export default router
