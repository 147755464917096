<template>
  <div class="yf-dialog">
    <el-dialog
      v-model="isShowDialog"
      v-bind="$attrs"
      :title="props.title"
      :width="props.width"
      :before-close="props.beforeClose"
      :destroy-on-close="props.destroyOnClose"
      :close-on-click-modal="props.closeOnClickModal"
      :show-close="!props.notShowClose"
    >
      <div class="main-content">
        <slot />
      </div>
      <template #footer>
        <span class="dialog-footer" v-if="buttonList && buttonList.length">
          <el-button
            v-for="item in buttonList"
            :key="item.event"
            :type="item.type"
            size="small"
            @click="handleChange(item.event)"
          >
            {{ item.label }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts" setup>
  import { ref, watch, computed } from 'vue'
  import type { IYfTableRowButton } from '@/components/YfTable/types'

  interface IProps {
    visible: boolean
    title: string
    width: string | number
    destroyOnClose?: boolean
    notShowClose?: boolean
    buttonList?: IYfTableRowButton[]
    beforeClose?: () => void
    closeOnClickModal?: boolean
  }

  interface IEmits {
    (e: 'footerButtonClick', parmas: string): void

    (e: 'update:visible', parmas: boolean): void
  }

  const props = withDefaults(defineProps<IProps>(), {
    destroyOnClose: true,
    width: '300px',
    closeOnClickModal: false
  })
  const buttonList = computed(() => {
    return props.buttonList?.filter((item) => !item.isHide)
  })
  const emit = defineEmits<IEmits>()

  const isShowDialog = ref<boolean>(props.visible)

  watch(
    () => props.visible,
    (val: boolean) => {
      isShowDialog.value = val
    }
  )

  const handleChange = (event: string) => {
    emit('footerButtonClick', event)
  }
</script>
<style lang="scss" scoped>
  .yf-dialog {
    :deep(.el-dialog__header) {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 22px;
      border-bottom: 1px solid #f3f7fb;
      margin: 0;
    }

    :deep(.el-dialog__body) {
      padding: 16px 24px 25px;
    }
  }
</style>
