<template>
  <div class="yf-address-info" :style="props.style">
    <yf-address
      v-model="value.addressInfo"
      :filterable="props.filterable"
      :props="props.props"
      @change="handleAddress"
    />
    <el-input
      v-model="value.address"
      placeholder="请输入详细地址"
      class="address"
      @change="handleAddress"
    />
  </div>
</template>

<script lang="ts" setup>
  import { ref, computed } from 'vue'
  import { IExtendObj } from '@/type'

  interface IProps {
    modelValue: IExtendObj
    props: IExtendObj
    disabled?: boolean
    filterable?: boolean
    style?: IExtendObj
  }

  /**
   * 自定义事件
   */
  interface IEmits {
    (e: 'update:modelValue', value: {}): void

    (e: 'change', value: {}): void
  }

  const props = defineProps<IProps>()
  const emit = defineEmits<IEmits>()

  const value = computed({
    get: () => {
      console.log(props)
      return props.modelValue
    },
    set: (val) => {
      emit('update:modelValue', val)
    }
  })
  const handleAddress = () => {
    emit('update:modelValue', value.value)
    emit('change', value.value)
  }
</script>

<style scoped lang="scss">
  .yf-address-info {
    display: flex;

    ::v-deep {
      .yf-address-class {
        width: 180px;
      }
    }

    .address {
      margin-left: 10px;
      width: calc(100% - 190px);
    }
  }
</style>
