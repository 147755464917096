import { defineStore } from 'pinia'
import { EOrderType } from '@/types'
import { getSubscribeQty } from '@/api/oc'
import { getMallOcSubscribeQty } from '@/api/mallOc'
import useMenuStore from '@/store/menu'

const useCartStore = defineStore({
  id: 'cart',
  state: () => {
    return {
      ocQty: 0,
      mallOcQty: 0
    } as {
      ocQty: number
      mallOcQty: number
    }
  },
  persist: {
    // 修改存储中使用的键名称，默认为当前 store的 id
    key: '__cart__',
    // 修改为 sessionstorage，默认为 localstorage
    storage: sessionStorage
  },
  actions: {
    async getCartQty(data: { pagePurchaseMode: string[] } = { pagePurchaseMode: ['1', '2'] }) {
      const useMenu = useMenuStore()
      const mode: string[] = []
      if (useMenu.getIsMenu('WholesaleOrder')) {
        mode.push(EOrderType.批发)
      }
      if (useMenu.getIsMenu('Order')) {
        mode.push(EOrderType.代发)
      }
      const pagePurchaseMode = data.pagePurchaseMode.filter((item) =>
        mode.includes(item.toString())
      )
      const funcMap = {
        1: getSubscribeQty,
        2: getMallOcSubscribeQty
      }

      const res = await Promise.all(
        pagePurchaseMode.map((type) => funcMap[Number(type)]({}, false))
      )
      pagePurchaseMode.forEach((type, index) => {
        this[type === '1' ? 'ocQty' : 'mallOcQty'] = res[index].content || 0
      })
    },
    removeCart() {
      this.ocQty = 0
      this.mallOcQty = 0
    }
  },
  getters: {
    cartQty(state) {
      return state.ocQty + state.mallOcQty
    }
  }
})
export default useCartStore
