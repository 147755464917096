<template>
  <notice />
  <router-view />
</template>

<script setup lang="ts">
  import Notice from '@/components/Notice/index.vue'
  import { computed, onMounted } from 'vue'
  import systemDictStroe from '@/store/dict'
  import useUserStore from '@/store/user'
  import useCustomerStore from '@/store/customer'
  import { useRoute, useRouter } from 'vue-router'
  import { nextTick } from 'vue'
  import router from './router'
  import useShopStore from '@/store/store'
  import { authValidate } from '@/store/auth'

  const userStore = useUserStore()
  const systemDict = systemDictStroe()
  const useCustomer = useCustomerStore()
  const useShop = useShopStore()
  const route = useRoute()
  if (!userStore.user?.token) {
    useShop.removeShop()
  }
  router.beforeEach(async (to, form) => {
    // 如果进入的内购店铺则不需要根据判断弹出认证、签署合同弹框
    if (useShop.store?.currentStore && Number(useShop.store?.currentStore.storeType) === 1) return
    // 如果进入的自营店铺则从登录页面进入首页或者个人中心需要根据判断弹出认证、签署合同弹框
    if ((to.name === 'HomePage' || to.name === 'account') && form.name === 'Login') {
      if (userStore.user?.token) {
        // 已激活 && (未认证 || 未签署)
        nextTick(async () => {
          if (Number(useCustomer.customer?.isActivation) === 1) {
            await authValidate(true)
          }
        })
      } else {
        await useCustomer.removeCustomerInfo()
      }
    }
  })

  onMounted(async () => {
    await systemDict.getDict()
    if (userStore.user?.token && useShop.store?.currentStore) {
      await useCustomer.getCustomerInfo()
    }
  })
  const key = computed(() => {
    return ![undefined, null, ''].includes(route.name)
      ? route.name + new Date()
      : route + new Date()
  })
</script>

<style>
  #app {
    font-family: PingFang SC, Microsoft YaHei, Tahoma, Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    /* width: 1920px; */
  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background: #c2c2c2;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }

  ::-webkit-scrollbar-track-piece {
    background: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #b6b6b6;
  }
</style>
