import { createI18n } from 'vue-i18n'
import en from './en.json'
import zh_CN from './zh_CN.json'

export const i18n = createI18n({
  locale: 'zh_CN',
  // fallbackLocale: 'en',
  legacy: false, // Composition API 模式
  globalInjection: true, // 全局注册 $t方法
  messages: {
    en,
    zh_CN
  }
})
