import {
  App,
  Component,
  createApp,
  h,
  getCurrentInstance,
  reactive,
  ComponentPublicInstance
} from 'vue'
import signContractDialog from './index.vue'

function mountComponent(RootComponent: Component) {
  const app = createApp(RootComponent)
  const root = document.createElement('div')

  document.body.appendChild(root)

  return {
    instance: app.mount(root),
    unmount() {
      app.unmount()
      document.body.removeChild(root)
    }
  }
}

let queue: ComponentPublicInstance[] = []
function createInstance() {
  const { instance, unmount } = mountComponent({
    setup() {
      const state = reactive({
        visible: false
      })
      const open = () => {
        state.visible = true
      }
      const close = () => {
        state.visible = false
        unmount()
      }
      const emit = {
        onResolve() {
          close()
          unmount()
        },
        onReject() {
          close()
          unmount()
        },
        onSuccess() {
          close()
        }
      }
      const render = () => {
        return h(signContractDialog, {
          ...state,
          ...emit
        })
      }
      // rewrite render function
      ;(getCurrentInstance() as any).render = render
      return {
        open,
        close
      }
    }
  })
  return instance
}

export function ShowSignContractDialog(options: any = {}) {
  let signContract: ComponentPublicInstance | null = null
  console.log(signContract)
  // if (queue.length) {
  //   signContract = queue[queue.length - 1]
  // } else {
  //
  // }
  signContract = createInstance()
  queue.push(signContract)
  console.log(queue)
  ;(signContract as any).open()
  return signContract
}
