<template>
  <yf-dialog
    :visible="visible"
    :title="title"
    :width="400"
    :buttonList="buttonList"
    :not-show-close="true"
    @footerButtonClick="handleFooterButtonClick"
  >
    <div style="text-align: center">
      <el-progress type="circle" :percentage="percentage" />
      <p>{{ tip }}</p>
    </div>
  </yf-dialog>
</template>
<script>
  import YfDialog from '@/components/YfDialog/index.vue'
  import { getTaskById } from '@/api/sc'
  import BatchErrorHandler from '@/utils/batchErrorHandler'
  import router from '@/router'
  import { ElMessage, ElMessageBox } from 'element-plus'
  export default {
    name: 'BatchTaskProgress',
    components: {
      YfDialog
    },
    data() {
      return {
        visible: false,
        title: '批量任务',
        tip: '批量任务执行中...',
        response: {},
        percentage: 1,
        buttonList: [
          {
            // 按钮类型
            type: 'primary',
            // 按钮文字
            label: '关闭',
            // 点击按钮触发事件
            event: 'handleHide'
          }
        ]
      }
    },
    mounted() {
      // this.visible = true
      // this.getTask()
    },
    methods: {
      handleFooterButtonClick(event) {
        this[event]()
      },
      resolve(data) {
        this.$emit('resolve', data)
      },
      reject(data) {
        this.$emit('reject', data)
      },
      clearInterval() {
        this.timer && clearInterval(this.timer)
      },
      getTask() {
        if (!this.response.taskId) {
          return
        }
        const secondQueue = [1, 2, 2, 3, 5]
        const lastSecond = secondQueue[secondQueue.length - 1]
        secondQueue.reverse()
        const getTime = () => (secondQueue.pop() || lastSecond) * 1000
        let second = 0
        const timeFunc = (time) => {
          second += time
          console.log('second', second)
          this.timer = setTimeout(async () => {
            const { content } = await getTaskById({ id: this.response.taskId })
            if (content.status === 'SUCCESS') {
              this.percentage = 100
              this.visible = false
              const result = JSON.parse(content.result)
              const requestParam = JSON.parse(content.requestParam)
              // 操作批量的单例
              const batchErrorHandler = BatchErrorHandler.create(result)
              // isFree 字段代表如果是操作批量接口，是否需要将返回结果处理方式让调用页面自由处理
              const { isFree = false } = requestParam.jsonData || {}
              // 是批量的操作接口的 做如下操作
              if (batchErrorHandler.getIsBatchPost() && !isFree) {
                // 判定是否需要抛出异常的接口
                let except = false
                // 判定是单条还是多条
                if (batchErrorHandler.getIsBatch()) {
                  // 判定 多条的时候 是否需要抛出异常
                  except = batchErrorHandler.getBatchIsException()
                  // 判定 多条的时候 是否需要做弹框显示
                  if (batchErrorHandler.getBatchShowErrorFlag()) {
                    // 判定是否需要二次弹框
                    if (batchErrorHandler.getIsConfirm()) {
                      this.resolve(batchErrorHandler.showBatchError())
                      return
                    }
                    batchErrorHandler.showBatchError()
                  }
                } else {
                  // 判定 单条的时候 是否需要抛出异常
                  except = batchErrorHandler.getSingleIsException()
                  // 判定 单条的时候 是否需要做弹框显示
                  except && batchErrorHandler.showSingleError()
                }
                // 将接口响应为异常
                if (except) {
                  return this.reject({ content: result })
                }
              }
              this.resolve({ content: result })
            } else if (content.status === 'FAIL') {
              this.$message.error(content.name + '任务执行失败')
              return this.reject({ content: {} })
            } else {
              if (second >= 4000) {
                this.visible = true
              }
              this.percentage = content.finishRate
              // if (this.percentage < 80) {
              //   this.percentage += Math.floor(Math.random() * 10 + 1)
              // } else if (this.percentage < 90) {
              //   this.percentage += Math.floor(Math.random() * 3 + 1)
              // } else if (this.percentage < 98) {
              //   this.percentage += 1
              // }
              timeFunc(getTime())
            }
          }, time)
        }
        timeFunc(getTime())
      },
      async handleHide() {
        ElMessageBox.confirm(
          '关闭弹框，任务将继续执行，具体执行结果请到【系统管理-任务管理】查看，是否确认关闭？',
          '关闭确认',
          {
            confirmButtonText: '关闭并跳转到任务管理',
            cancelButtonText: '关闭',
            type: 'warning',
            distinguishCancelAndClose: true
          }
        )
          .then(() => {
            this.visible = false
            this.clearInterval()
            router.push({ name: 'task' })
            this.reject()
          })
          .catch((action) => {
            console.log('action', action)
            if (action === 'cancel') {
              this.visible = false
              this.clearInterval()
              this.reject()
            }
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .code-img {
    display: block;
    margin: 0px auto;
    width: 120px;
    height: 120px;
  }
</style>
