<template>
  <el-radio-group v-model="value" @change="handleChange">
    <el-radio
      v-for="item in props.options"
      :key="item.value"
      :label="item.value"
      v-bind="props.itemAttrs"
      >{{ item.label }}</el-radio
    >
  </el-radio-group>
</template>
<script setup lang="ts">
  import { ref, computed } from 'vue'
  import type { IExtendObj } from '@/types'

  interface IYfRadioProps {
    modelValue: string | number | boolean
    disabled?: boolean
    options: IExtendObj[]
    itemAttrs: IExtendObj
  }
  /**
   * 自定义事件
   */
  interface IYfRadioEmits {
    (e: 'update:modelValue', value: string | number | boolean): void
  }
  const props = defineProps<IYfRadioProps>()
  const emit = defineEmits<IYfRadioEmits>()
  const value = computed({
    get: () => props.modelValue,
    set: (val) => {
      emit('update:modelValue', val)
    }
  })
  const handleChange = (value: string | number | boolean) => {
    emit('update:modelValue', value)
  }
</script>
<style lang="scss"></style>
