import { defineStore } from 'pinia'
import { getDictValue } from '@/api/sc'
import type { IExtendObj } from '@/types'
// 要设置成枚举值的属性
const valueKey = ['label', 'extend1', 'extend2', 'extend3', 'extend4', 'extend5']
// 要设置成枚举值的字典code
const dictCode = ['currencyType']
const systemDictStroe = defineStore({
  id: 'dict',
  state: () => {
    return {
      dict: {},
      // 后续有其他字典再手动添加相关的字典组名称
      dictCodeList: [
        'mall_afterSaleType', // 接单工具 - 售后类型
        'mall_refundReason', // 接单工具 - 退款原因
        'mall_manageTask_status', // 接单工具-任务管理-状态
        'LOGISTICS_OPERATE_TYPE', // 物流跟踪状态
        'returnReason', // 退货原因
        'oc_cancel_reason', // 订单取消原因
        'invoiceHead', // 发票抬头
        'invoiceTyp', // 发票种类
        'sourceBill', // 来源单据
        'outputInvoiceStatus', // 开票状态（销项发票）
        'clienIdentity', // 客户身份
        'mdm_platform_channel_type', // 平台档案渠道类型
        'storeType', // 店铺类型
        'gether_pay_terms', // 收付款方式
        'payerIdentity', // 付款人身份
        '1CreditLevel', // 淘宝信用级别
        'activityType', // 赠品活动
        'activity_type_name', // 批发订单-活动类型名称
        'saleclient_address_type', // 收货地址类型
        'invoiceOrderSource', // 订单来源
        'actualSingleType', // 签约人类型
        'buy_again_fail_reason',
        'platform_gether_pay_terms', // 支付方式
        'newcCassify' // 商品类型
      ]
    } as { dict: IExtendObj; dictCodeList: string[] }
  },
  actions: {
    async getDict() {
      const dictCodeList = this.dictCodeList.join(',')
      const { content } = await getDictValue(dictCodeList)
      content.forEach((item) => {
        this.dict[item.dictCode] = item.dictValueList
      })
    },
    removeDict() {
      this.dict = {}
    }
  },
  getters: {
    dictKv(state) {
      return dictCode.reduce((prev: IExtendObj, curr) => {
        const data = state.dict[curr] || []
        prev[curr] = valueKey.reduce((prev: IExtendObj, curr) => {
          prev[curr] = data.reduce((prev: IExtendObj, current: any) => {
            prev[current.value] = current[curr]
            return prev
          }, {})
          return prev
        }, {})
        return prev
      }, {})
    }
  }
})
export default systemDictStroe
