import Vue, { createApp } from 'vue'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import store from '@/store/index'
import 'element-plus/dist/index.css'
import App from '@/App.vue'
import '@/styles/index.scss'
import '@/styles/element/reset.scss'
import '@/styles/wangEditor/text.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Components from '@/components/index'
import router from '@/router'
import Directive from '@/directives/index'
import BigNumber from 'bignumber.js'
import 'virtual:svg-icons-register'
import svgIcon from '@/components/SvgIcon/index.vue'
import { post, postAxios } from '@/utils/request'
import { IExtendObj } from '@/types'
import { i18n } from '@/locales/index'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 使用4j的请求兼容 自定义添加
function $axios<T>(requstConfig: any, config?: any) {
  return post<T>(`/api/mdm${requstConfig.url}`, 'POST', requstConfig.data, undefined, config)
}
$axios.get = function (url: string, data: any) {
  console.log(data, 'params')
  return postAxios<IExtendObj>(`/api/mdm${url}`, { jsonData: data?.params })
}
$axios.post = function (url: string, data: IExtendObj) {
  return postAxios<IExtendObj>(`/api/mdm${url}`, { jsonData: data?.data })
}

app.config.globalProperties.$axios = $axios

app.component('svg-icon', svgIcon)
app.config.globalProperties.BigNumber = BigNumber
app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: zhCn
  // size: 'small'
})
app.use(Components)
app.use(Directive)
app.use(i18n)

app.mount('#app')
