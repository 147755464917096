import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'
import type { IExtendObj } from '@/types'
import type { IColumn } from '@/components/BaseFormDialog/types'
import type { TableColumnCtx } from 'element-plus/es/components/table/src/table-column/defaults'

interface SummaryMethodProps<T = IExtendObj> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

export const coryText = (text = 'text', func?: any) => {
  type DomType = HTMLElement | null
  const copyDom: DomType = document.getElementById('app')
  if (copyDom) {
    const clipboard = new ClipboardJS(copyDom, {
      text: () => text
    })
    const timer = setInterval(() => {
      copyDom.click()
    }, 500)
    clipboard.on('success', (e) => {
      func ? func() : ElMessage.success('复制成功')
      clearInterval(timer)
      // 释放内存
      clipboard.destroy()
    })
    clipboard.on('error', (e) => {
      // 不支持复制
      ElMessage.error('该浏览器不支持自动复制')
      clearInterval(timer)
      // 释放内存
      clipboard.destroy()
    })
  }
}

// 判断数组是否存在重复 重复返回true
export const isAllEqual = (array: string[]) => {
  return !array.some((value: string) => {
    return value !== array[0]
  })
}

export const getColumn = (column: IColumn[], prop: string): IExtendObj => {
  return column.find((item: IExtendObj) => item.prop === prop) || {}
}

export const getSummariesMethod = (
  params: SummaryMethodProps,
  showArgs: string[], // 需要合计的行
  moneyArgs?: string[], // 合计金额的数据
  isSelectTotal?: boolean // 是否展示选中合计
) => {
  const { columns, data } = params
  const list = []
  const sums: (string | number)[] = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '合计'
      return
    }
    const values = data.map((item) => Number(item[column.property]))
    if (showArgs.includes(column.property)) {
      if (!values.every((value) => Number.isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr)
          if (!Number.isNaN(value)) {
            return prev + curr
          } else {
            return prev
          }
        }, 0)
        if (moneyArgs && moneyArgs.length && moneyArgs.includes(column.property))
          sums[index] = thousandFormatter(Number(sums[index]).toFixed(2))
      } else {
        sums[index] = ''
      }
    }
  })
  list.push(sums)
  if (isSelectTotal) {
    const checkSum: (string | number)[] = []
    columns.forEach((column, index) => {
      const values = data.map((item) => Number(item[column.property]))
      if (showArgs.includes(column.property)) {
        if (!values.every((value) => Number.isNaN(value))) {
          checkSum[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          if (moneyArgs && moneyArgs.length && moneyArgs.includes(column.property))
            checkSum[index] = thousandFormatter(Number(checkSum[index]).toFixed(2))
        } else {
          checkSum[index] = ''
        }
      }
    })
    checkSum[0] = '选中合计'
    list.unshift(checkSum)
  }
  console.log(sums)
  return list
}

/**
 * 千分位格式化
 * @param {number|string} num 金额
 * @returns
 */
export function thousandFormatter(num: string | null | undefined | any, toFixed = 2) {
  if (['', null, undefined].includes(num)) return ''
  num = String(num).replace(/,/g, '')
  const numStr = Number(num).toFixed(toFixed)
  const numArr = numStr.split('.')
  let c = numArr[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  if (numArr[1] && numArr[1].toString()) {
    c += '.'
    c += numArr[1]
  }
  return c
}

/**
 * 将一个数组分为二维数组
 * @param array
 * @param subGroupLength
 */
export function groupAry(array: any[], subGroupLength: number) {
  let index = 0
  let newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)))
  }
  return newArray
}
/**
 * 判断val是否为空
 * @param val
 */
export function isNull(val: any) {
  if (Array.isArray(val)) {
    return !val.length
  }
  return ['', null, undefined].includes(val)
}

/**
 * 阿拉伯数字转换中文
 * @param number
 */
export function getNum(number: number | string) {
  let zhArray = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'] // 数字对应中文
  let baseArray = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万'] //进位填充字符，第一位是 个位，可省略
  let string = String(number)
    .split('')
    .reverse()
    .map((item, index) => {
      item = Number(item) === 0 ? zhArray[Number(item)] : zhArray[Number(item)] + baseArray[index]
      console.log(item)
      return item
    })
    .reverse()
    .join('') // 倒叙回来数组，拼接成字符串
  string = string.replace(/^一十/, '十') // 如果是以 一十 开头便可省略“一”
  string = string.replace(/零+/, '零') // 如果有多位相邻的零，只写一个即可
  return string
}

/**
 * 获取数字部分和单位部分，超过10万以万为单位显示
 * @param num
 * @returns {string}
 */
export function getMoneyAndUnit(num: any) {
  const obj = { value: 0, unit: '' }
  if (['', null, undefined].includes(num)) return obj
  if (isNaN(num) || isNaN(Number(num))) return obj
  if (num <= 100000) return { value: thousandFormatter(num), unit: '' }
  // 999000.99=>9.99w
  obj.value = num.toString().split('.')[0].slice(0, -2) / 100
  obj.unit = '万'
  return obj
}

/**
 * 获取数组按照升序、降序
 * @param list
 * @param order
 */

export function getSort(list: IExtendObj, prop: string, order: string) {
  const data = list.sort(
    (a: { [x: string]: number | string }, b: { [x: string]: number | string }) => {
      if (a[prop] === b[prop]) {
        return 0
      } else if (a[prop] === null || a[prop] === '') {
        return 1
      } else if (b[prop] === null || b[prop] === '') {
        return -1
      } else if (order === 'ascending') {
        return a[prop] < b[prop] ? -1 : 1
      } else {
        return a[prop] < b[prop] ? 1 : -1
      }
    }
  )
  return data
}
