<template>
  <el-button v-bind="$attrs">{{ props.text }}</el-button>
</template>
<script setup lang="ts">
  import { computed, reactive } from 'vue'
  /**
   * 表格配置属性
   */
  interface IYfButtonProps {
    text: string
  }
  const props = defineProps<IYfButtonProps>()
</script>

<style scoped lang="scss"></style>
