import {
  App,
  Component,
  createApp,
  h,
  getCurrentInstance,
  reactive,
  ComponentPublicInstance
} from 'vue'
import routerInstance from '@/router'
import LoginDialog from './LoginDialog.vue'
import router from '@/router/index'
import useDecorateStore from '@/store/decorate'

import useShopStore from '@/store/store'
import { changeUserStore, IStore } from '@/api/sc'

let queue: ComponentPublicInstance[] = []

function mountComponent(RootComponent: Component) {
  const app = createApp(RootComponent)
  const root = document.createElement('div')

  document.body.appendChild(root)

  return {
    instance: app.mount(root),
    unmount() {
      app.unmount()
      queue = []
      document.body.removeChild(root)
    }
  }
}

function createInstance(resolve: () => void = () => {}, reject: () => void = () => {}) {
  const { instance, unmount } = mountComponent({
    setup() {
      const state = reactive({
        show: false
      })
      const open = () => {
        state.show = true
      }
      const close = () => {
        state.show = false
        unmount()
      }
      const emit = {
        async onLoginSuccess(isNewUser: boolean) {
          const useShop = useShopStore()
          // 如果重新的登录的新用户，则直接跳转到【首页】
          isNewUser && router.replace({ path: '/homePage' })
          if (!isNewUser) {
            await changeUserStore({ cusStoreId: useShop.store?.currentStore.cusStoreId })
          }

          // 刷新装修数据
          const decorateStore = useDecorateStore()
          const {
            path,
            query: { renovationId }
          } = routerInstance.currentRoute.value
          if (path === '/select-decorate') {
            await decorateStore.getSelectDecorate(renovationId)
          } else {
            await decorateStore.getHomePageDecorate()
          }
          close()
        },
        onClose: close
      }
      const render = () => {
        return h(LoginDialog, {
          ...state,
          ...emit
        })
      }
      // rewrite render function
      ;(getCurrentInstance() as any).render = render
      return {
        open,
        close
      }
    }
  })
  return instance
}

export function ShowLoginDialog(options: any = {}) {
  let instance: ComponentPublicInstance | null = null
  console.log(instance)
  if (queue.length) {
    instance = queue[queue.length - 1]
  } else {
    instance = createInstance()
    queue.push(instance)
  }
  ;(instance as any).open()
  return instance
}
