<template>
  <el-date-picker v-bind="$attrs" :default-time="defaultTime" />
</template>
<script setup lang="ts">
  import { computed, useAttrs } from 'vue'
  const $attrs = useAttrs()
  const defaultTime: any = computed(
    () => $attrs.defaultTime ?? [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
  )
</script>

<style scoped lang="scss"></style>
