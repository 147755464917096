import { defineStore } from 'pinia'
import _ from 'lodash'

const useAdvertisingStore = defineStore({
  id: 'advertising',
  state: () => {
    return {
      advertisingShowTime: 0,
      advertisingLastShowTimetemp: '2023-11-11 11:11:11'
    } as { advertisingShowTime: number; advertisingLastShowTimetemp: string }
  },
  persist: {
    // 修改存储中使用的键名称，默认为当前 store的 id
    key: '__advertising__',
    // 修改为 sessionstorage，默认为 localstorage
    storage: sessionStorage
  },
  actions: {
    async updateAdvertisingShowTime() {
      this.advertisingShowTime = this.advertisingShowTime + 1
    },
    updateAdvertisingLastShowTimetemp(advertisingLastShowTimetemp: string) {
      this.advertisingLastShowTimetemp = advertisingLastShowTimetemp
    },
    // 登录成功的时候 会重新计算
    // src/components/LoginComponent/index.vue
    resetAdvertising() {
      this.advertisingShowTime = 0
      this.advertisingLastShowTimetemp = '2023-11-11 00:00:00'
    }
  }
})
export default useAdvertisingStore
