import { ElMessage, ElMessageBox } from 'element-plus'
import '@/styles/batch-error-message-box.scss'

export default class BatchErrorHandler {
  defaultColumnWidth: number
  static instance?: BatchErrorHandler
  content: any

  static create(content: any) {
    if (this.instance) {
      this.instance.update(content)
    } else {
      this.instance = new BatchErrorHandler(content)
    }
    return this.instance
  }

  constructor(content: any) {
    /**
     * isBatch 判断是否是批量操作
     * errorIds 错误的id的集合
     * errorMessageList 全部的操作结果的信息
     * successTotal 成功的条数
     * errorTotal 失败的条数
     * total 总共操作的条数
     */
    this.content = content
    this.defaultColumnWidth = 280
  }

  update(content: any) {
    this.content = content
  }

  /**
   * 是否是批量操作的接口, 必须同时拥有下面几个字段
   * @returns {Boolean} 是否是批量操作接口
   */
  getIsBatchPost() {
    // 返回必须是对象
    if (typeof this.content !== 'object') return false

    return ['isBatch', 'errorIds', 'errorMessageList'].reduce((bool, key) => {
      bool = bool && Reflect.has(this.content, key)
      return bool
    }, true)
  }

  /**
   * 获取是单条 还是 多条的
   */
  getIsBatch() {
    return this.content.isBatch
  }

  /**
   * 获取是否需要二次确认
   */
  getIsConfirm() {
    return !!this.content.isConfirm
  }

  /** ************* 单条的时候 ***************/
  /**
   * 获取是单条的时候是否需要将接口处理为异常
   */
  getSingleIsException() {
    return this.content.errorTotal > 0
  }

  /**
   * 单条错误的弹框操作
   */
  showSingleError() {
    // 获取 第一条的 message
    const [{ message }] = this.content.errorMessageList
    ElMessage({ message, type: 'error', duration: 5 * 1000 })
  }

  /** ************* 多条的时候 ***************/
  /**
   * 获取是多条的时候是否需要将接口处理为异常
   */
  getBatchIsException() {
    return [0, null].includes(this.content.successTotal)
  }

  /**
   * 获取是多条的时候是否需要弹框
   */
  getBatchShowErrorFlag() {
    return this.content.errorTotal > 0
  }

  /**
   * 获取自定义表头
   * @returns Array<Column:{label:string,prop:string}>
   */
  getColumnList() {
    const { columnList } = this.content
    if (columnList && columnList.length) {
      return columnList
    } else {
      return [
        { label: '单据编号', prop: 'billNo', width: this.defaultColumnWidth },
        { label: '执行结果', prop: 'message', width: this.defaultColumnWidth }
      ]
    }
  }

  /**
   * 多条错误的弹框操作
   */
  showBatchError() {
    const columnList = this.getColumnList()
    let html = ''
    html += `<table class="error-batch-message-box__table" style="width:${columnList.reduce(
      (width: number, column: { width: number }) =>
        width + (column.width || this.defaultColumnWidth),
      80
    )}px;">`
    html += `  <thead>`
    html += `    <tr>`
    html += `      <th class="error-batch-message-box__table-index">序号</th>`
    html += columnList
      .map(
        (column: { width: number | string; label: string }) =>
          `<th class="error-batch-message-box__table-no" style="width:${
            column.width || this.defaultColumnWidth
          }px;">${column.label}</th>`
      )
      .join('')
    html += `    </tr>`
    html += `  </thead>`
    html += `  <tbody>`

    this.content.errorMessageList.forEach((item: any, index: any) => {
      html += `    <tr>`
      html += `      <td class="error-batch-message-box__table-index">${index + 1}</td>`
      html += columnList
        .map(
          (column: { width: number | string; prop: string | number }) =>
            `<td class="error-batch-message-box__table-no" style="width:${
              column.width || this.defaultColumnWidth
            }px;">${item[column.prop]}</td>`
        )
        .join('')
      html += `    </tr>`
    })

    html += `  </tbody>`
    html += `</table>`

    const { successTotal, errorTotal, errorMessageTitle } = this.content
    // && !this.getIsConfirm()
    if (!errorMessageTitle) {
      let tm: any = setTimeout(() => {
        ElMessage({
          message: `成功 ${successTotal || 0} 条，失败 ${errorTotal || 0} 条`,
          type: 'success',
          duration: 5 * 1000
        })
        clearTimeout(tm)
        tm = null
      }, 50)
    }
    // let tm: any = setTimeout(() => {
    //   ElMessage({
    //     message: `成功 ${successTotal} 条，失败 ${errorTotal} 条`,
    //     type: 'success',
    //     duration: 5 * 1000
    //   })
    //   clearTimeout(tm)
    //   tm = null
    // }, 50)
    return ElMessageBox.confirm(html, errorMessageTitle || '执行结果', {
      customClass: 'error-batch-message-box',
      showClose: false,
      showCancelButton: this.getIsConfirm(),
      confirmButtonText: this.content.confirmButtonText || '确定',
      cancelButtonText: this.content.cancelButtonText || '取消',
      closeOnClickModal: false,
      dangerouslyUseHTMLString: true
    })
  }
}
