import { defineComponent, useAttrs, h, defineEmits, ref, onMounted, watch } from 'vue'
import dictStroeHook from '@/store/dict'
import type { IExtendObj } from '@/types'
interface SetupContext {
  //这里还有其他的，为了避免混淆，就不写了。
  emit: (event: string, ...args: unknown[]) => void
}
type AttrType = Record<string, any>

import { request } from '@/utils/request'

export default function WithAutoLoadHoc(WrapperComponent: any) {
  const dictStore = dictStroeHook()
  return defineComponent({
    setup(props: IExtendObj, { emit }: SetupContext) {
      interface IYfSelectEmits {
        (e: 'autoloadCallback', val: any): void
      }
      const attrs: AttrType = useAttrs()
      const options = ref<Array<any>>([])
      function autoLoadDict() {
        options.value = dictStore.dict[attrs.dictCode]
      }
      async function autoLoadServer() {
        try {
          const { content } = await request<Array<any> | { records: Array<any> }>(
            attrs.autoLoad.url,
            attrs.autoLoad.method,
            attrs.autoLoad.data,
            attrs.autoLoad.params,
            attrs.autoLoad.config || {}
          )
          if (Array.isArray(content)) {
            options.value = content
          } else {
            options.value = content.records
          }
          emit('autoloadCallback', options.value)
        } catch (error) {
          console.error(error)
        }
      }
      watch(() => attrs.dictCode, autoLoadDict)
      watch(() => attrs.autoLoad, autoLoadServer)
      onMounted(() => {
        if (attrs.options) {
          return
        }
        if (attrs.dictCode) {
          autoLoadDict()
          return
        }
        if (attrs.autoLoad) {
          autoLoadServer()
          return
        }
      })
      return () =>
        h(WrapperComponent, {
          ...attrs,
          options: options.value
        })
    }
  })
}
