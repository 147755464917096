<template>
  <div>
    <el-image
      v-for="(img, index) in imageUrl || []"
      :key="img"
      :src="img"
      :fit="fit"
      :style="{ borderRadius: borderRadius }"
      @click.stop="handleClickItem(imgList[index])"
    >
      <!-- <template #placeholder>
        <div class="image-slot">
          <el-image :src="goodImg"></el-image>
        </div>
      </template> -->
      <template #error>
        <div class="image-slot">
          <el-image :src="goodImg"></el-image>
        </div>
      </template>
    </el-image>
    <teleport v-if="showViewer && isShowViewer" to="body">
      <!--        <el-image-viewer-->
      <!--          v-if="showViewer && !isViewer"-->
      <!--          :z-index="zIndex"-->
      <!--          :initial-index="0"-->
      <!--          @close="closeViewer"-->
      <!--          :url-list="imgList"-->
      <!--        />-->
      <div v-if="showViewer && isViewer" class="big-viewer">
        <div class="mask"></div>
        <div class="img-viewer">
          <div class="view-close" @click="closeViewer">x</div>
          <el-image :src="viewerImg" fit="contain" />
        </div>
      </div>
    </teleport>
  </div>
</template>
<script>
  import Signature from '@/utils/signature'
  import goodImg from '@/assets/goodsImg.png'

  export default {
    name: 'YFImage',
    props: {
      modelValue: { type: [String, Array], default: '' },
      lazy: { type: Boolean, default: false },
      zIndex: { type: [String, Number], default: 10000 },
      thumbnail: { type: Boolean, default: true },
      isShowViewer: { type: Boolean, default: false },
      borderRadius: { type: [String, Number], default: '' },
      fit: { type: String, default: 'cover' },
      isViewer: { type: Boolean, default: true },
      nullImgSrcShowDefault: { type: Boolean, default: false }, // 图片地址为空的时候是否展示默认图片
      isPublic: {
        type: Boolean,
        default: false
      },
      width: { type: Number, default: 100 },
      height: { type: Number, default: 100 }
    },
    data() {
      return {
        goodImg: goodImg,
        imageUrl: [],
        isFirst: false,
        imgList: [],
        showViewer: false,
        viewerImg: ''
      }
    },
    watch: {
      modelValue: {
        async handler(value) {
          if (this.nullImgSrcShowDefault) {
            value = !value ? 'default.png' : value
          }
          // if (!this.isFirst) {
          //   this.isFirst = true
          this.imageUrl = []
          await this.getImgUrl(value)
          // }
        },
        immediate: true
      }
    },
    methods: {
      handleClickItem(img) {
        if (this.isShowViewer) {
          this.showViewer = true
          this.viewerImg = img
        } else {
          this.$emit('click')
        }
      },
      closeViewer() {
        // 关闭
        this.showViewer = false
        // console.log(this.showViewer)
      },
      async getImgUrl(path) {
        // console.log(111, path)
        const imgAry = []
        this.imageUrl = []
        if (!Array.isArray(path)) {
          // if
          imgAry.push(path)
        } else {
          imgAry.push(...path)
        }
        imgAry.forEach(async (item, i) => {
          let newPath = ''
          if (item) {
            const Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
            const objExp = new RegExp(Expression)
            if (objExp.test(item)) {
              newPath = item
              this.imgList.push(newPath)
            } else {
              const signature = Signature.getInstance(this.isPublic)
              const client = await signature.get()
              newPath = await this.scale(client, item)
            }
          }
          this.imageUrl.push(newPath)
        })
      },
      async scale(client, path) {
        try {
          let url = ''
          if (this.isPublic) {
            url = `https://${client.getBucket()}.${client.options.region}.aliyuncs.com/${path}`
          } else {
            url = await client.signatureUrl(path)
          }
          let smallUrl = ''
          const ossParams = `image/resize,m_pad,w_${this.width},h_${this.height}`
          if (this.isPublic) {
            smallUrl = `${url}?x-oss-process=${ossParams}`
          } else {
            smallUrl = await client.signatureUrl(path, {
              process: ossParams
            })
          }
          this.imgList.push(url)
          // console.log('imgList', this.imgList)
          return smallUrl
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .el-image {
    width: 100%;
    height: 100%;
  }

  .image-slot {
    width: 100%;
    height: 100%;
  }

  .image-slot-none {
    width: 100%;
    height: 100%;
  }
  .big-viewer {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .img-viewer {
      width: 500px;
      height: 500px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .el-image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .view-close {
      position: absolute;
      top: -50px;
      right: -50px;
      width: 44px;
      height: 44px;
      background: #666666;
      border-radius: 100%;
      color: #fff;
      text-align: center;
      line-height: 41px;
      font-size: 24px;
      cursor: pointer;
    }
  }
</style>
