import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash'
import { EOrderType, IExtendObj } from '@/types'
import router from '@/router'
import { defineAsyncComponent } from 'vue'
import { selectMenuTree } from '@/api/sc'
const modules = import.meta.glob('../views/**/**.vue')
const routers = [
  {
    path: '/wholesaleOrder/result',
    component: () => import('@/views/result/index.vue'),
    name: 'OrderWholesaleResult',
    parent: { name: 'WholesaleOrder' },
    title: '下单成功',
    meta: {
      title: '下单成功'
    }
  },
  {
    path: '/stock/result/success',
    component: () => import('@/views/result/index.vue'),
    name: 'StockWholesaleResultSuccess',
    parent: { name: 'CartList' },
    title: '下单成功',
    meta: {
      title: '下单成功'
    }
  },
  {
    path: '/stock/result/error',
    component: () => import('@/views/result/index.vue'),
    name: 'StockWholesaleResultError',
    parent: { name: 'CartList' },
    title: '下单失败',
    meta: {
      title: '下单失败'
    }
  },
  {
    path: '/wholesaleOrder/pay/result',
    component: () => import('@/views/wholesaleOrder/payResult.vue'),
    name: 'PayOrderWholesaleResult',
    parent: { name: 'WholesaleOrder' },
    title: '订单支付结果',
    meta: {
      title: '订单支付结果'
    }
  },
  {
    path: '/order/pay/result',
    component: () => import('@/views/order/payResult.vue'),
    name: 'PayOrderResult',
    parent: { name: 'WholesaleOrder' },
    title: '订单支付结果',
    meta: {
      title: '订单支付结果'
    }
  },
  {
    path: '/customerService/result',
    component: () => import('@/views/customerService/result.vue'),
    name: 'invoiceResult',
    title: '开票成功',
    meta: {
      title: '开票成功'
    }
  },
  {
    path: '/purchasingAnalysis/purchaseTotal',
    component: () => import('@/views/purchasingAnalysis/purchaseTotal.vue'),
    name: 'purchaseTotal',
    title: '采购分析',
    meta: {
      title: '采购分析'
    }
  }
]
async function getMenu() {
  const { content } = await selectMenuTree()
  let list = []
  const buttonCodeList: string[] = []
  const menuCodeList: string[] = []
  if (content.length) {
    list = content[0].children.map((item: any) => {
      const group = {
        title: item.menuName,
        type: 'group',
        children: item.children.map((m: any) => {
          const menu = {
            title: m.menuName,
            path: m.fileUrl || m.menuUrl,
            routerPath: m.menuUrl,
            name: m.routeName,
            type: 'menu',
            menuCode: m.menuCode,
            isHidden: m.isHidden === 1,
            children: m.permissionValue.extendActionPermissionList
              .filter((item: any) => item.hasPermission)
              .map((b: any) => {
                let button = {
                  title: b.actionName,
                  path: b.actionUrl,
                  name: b.routeName,
                  type: 'sub',
                  actionCode: b.actionCode
                }
                let config = {}
                if (b.actionConfig) {
                  config = JSON.parse(b.actionConfig)
                }
                buttonCodeList.push(b.actionCode)
                return { ...button, ...config }
              })
          }
          let config = {}
          if (m.menuConfig) {
            config = JSON.parse(m.menuConfig)
          }
          menuCodeList.push(m.menuCode)

          return { ...menu, ...config }
        })
      }
      return group
    })
  }

  return {
    menuList: list,
    menuCodeList,
    buttonCodeList
  }
}
export interface IMenu {
  title: string
  icon?: string
  path?: string
  // parent?: IMenu
  parent?: { name?: string; title: string }
  name?: string
  type: string
  routerPath?: string
  menuIndex?: string
  children?: Array<IMenu>
}
export function getList(menuList: IMenu[], list: IMenu[] = [], menuIndex = '') {
  menuList.forEach((item) => {
    menuIndex && (item.menuIndex = menuIndex)
    const r = cloneDeep(item)
    delete r.children
    list.push(r)
    if (item.children && item.children.length) {
      item.children.forEach((c) => {
        // c.parent = { title: item.title, name: item.name, type: item.type, parent: item.parent }
        c.parent = { name: item.name, title: item.title }
      })
      getList(item.children, list, item.type === 'menu' ? item.path : menuIndex)
    }
  })
}
const useMenuStore = defineStore({
  id: 'menu',
  state: () => {
    return {
      menuList: [],
      routerList: [],
      code: { buttonCodeList: [], menuCodeList: [] }
    } as {
      menuList: IMenu[]
      routerList: any
      code: { buttonCodeList: string[]; menuCodeList: string[] }
    }
  },
  persist: true,
  actions: {
    handleMenuByRouterName(menuList: any) {
      let list: IMenu[] = cloneDeep(menuList)
      this.menuList = list
      const routerList: IMenu[] = []
      getList(list, routerList)
      this.routerList = routerList
        .filter(
          (item) =>
            item.name && !!(item.routerPath || item.path) && !['Register'].includes(item.name)
        )
        .map((item: IMenu) => {
          const router: IExtendObj = {
            ...item,
            meta: item,
            path: item.routerPath || item.path
          }
          if (item.path) {
            router.component = modules[`../views${item.path}.vue`]
          }
          return router
        })
      this.routerList.push(...routers)
    },
    async handleRoute(menuList: any) {
      this.handleMenuByRouterName(menuList)
      router.removeRoute('home')
      await router.addRoute({
        path: '/',
        name: 'home',
        component: defineAsyncComponent(() => import('../components/layout/AppMain/index.vue')),
        children: this.routerList
      })
    },
    async handleMenu() {
      const { menuList, buttonCodeList, menuCodeList } = await getMenu()
      this.code.buttonCodeList = buttonCodeList
      this.code.menuCodeList = menuCodeList
      await this.handleRoute(menuList)
    },
    getIsMenu(code: string) {
      return this.menuCodeList.includes(code)
    },
    getIsButton(code: string) {
      return this.buttonCodeList.includes(code)
    }
  },
  getters: {
    buttonCodeList(state) {
      return state.code.buttonCodeList
    },
    menuCodeList(state) {
      return state.code.menuCodeList
    }
  }
})
export default useMenuStore
