import { ShowSignContractDialog } from '@/components/signContract/signContractModal'
import { ShowAuthenticDialog } from '@/components/authenticationDialog/authenticaModal'
import { ShowPerfectRegister } from '@/components/authPerfectRegister/authPerfectRegisterModal'
import { ShowReviewtRegister } from '@/components/authReviewtRegister/authReviewtRegisterModal'
import { ShowLoginDialog } from '@/components/LoginComponent/LoginDialogModel'
import useShopStore from '@/store/store'
import useUserStore from '@/store/user'
import useCustomerStore from '@/store/customer'

export async function authValidate(init: boolean = false) {
  const userStore = useUserStore()
  const useShop = useShopStore()
  const useCustomer = useCustomerStore()
  if (!userStore.user?.token) {
    ShowLoginDialog()
    return true
  }

  if (userStore.user?.type === 1) return false
  //已登录，未完善注册信息
  if (userStore.user && useCustomer.customer.approvalStatus === null) {
    try {
      await ShowPerfectRegister()
      console.log('PerfectRegister')
    } catch (e) {
    } finally {
      return true
    }
  }
  //已登录，审核中
  if (userStore.user && Number(useCustomer.customer.approvalStatus) === 1) {
    try {
      await ShowReviewtRegister()
      console.log('ReviewtRegister')
    } catch (e) {
    } finally {
      return true
    }
  }

  // 没有认证是否允许下单
  const isAllowNoCertification = useShop.store?.currentStore?.isUnauthorizedOrder === '1'
  // 没有签署合同是否允许下单
  const isAllowNoSign = useShop.store?.currentStore?.isSignOrder === '1'

  // 初始化 不根据判断参数，只根据客户是否认证或者签署合同，没有认证弹出认证弹框、没有签署合同弹出合同弹框
  if (init) {
    if ([1, 3].includes(Number(useCustomer.customer?.authenticationStatus))) {
      await ShowAuthenticDialog()
    } else {
      if ([1, 3, 4].includes(Number(useCustomer.customer?.signContractStatus))) {
        await ShowSignContractDialog()
      }
    }
  } else {
    // 后续点击按钮需要客户是否认证和是否签署合同与参数权限一起控制
    if (
      Number(useCustomer.customer?.authenticationStatus) === 2 &&
      Number(useCustomer.customer?.signContractStatus) === 2
    ) {
      // 已认证、已签署不弹框
      return false
    } else if (
      [1, 3].includes(Number(useCustomer.customer?.authenticationStatus)) &&
      [1, 3, 4].includes(Number(useCustomer.customer?.signContractStatus))
    ) {
      // 没有认证签署
      if (isAllowNoCertification && isAllowNoSign) {
        // 系统参数允许下单
        return false
      } else if (!isAllowNoCertification) {
        // 未认证不允许下单
        try {
          await ShowAuthenticDialog()
          console.log('Authentic')
        } catch (e) {
        } finally {
          return true
        }
      } else if (isAllowNoCertification && !isAllowNoSign) {
        // 未认证可以下单、未签署不能下单
        try {
          await ShowSignContractDialog()
          console.log('SignContract')
        } catch (e) {
        } finally {
          return true
        }
      }
    } else if (
      [1, 3].includes(Number(useCustomer.customer?.authenticationStatus)) &&
      [2].includes(Number(useCustomer.customer?.signContractStatus))
    ) {
      // 未认证、签署合同
      if (isAllowNoCertification) {
        // 未认证不允许下单
        return false
      } else {
        // 未认证允许下单
        try {
          await ShowAuthenticDialog()
          console.log('Authentic')
        } catch (e) {
        } finally {
          return true
        }
      }
    } else if (
      [2].includes(Number(useCustomer.customer?.authenticationStatus)) &&
      [1, 3, 4].includes(Number(useCustomer.customer?.signContractStatus))
    ) {
      // 认证、未签署
      if (isAllowNoSign) {
        // 未签署不允许下单
        return false
      } else {
        // 未签署允许下单
        try {
          const res = await ShowSignContractDialog()
          console.log('Sign', res)
        } catch (e) {
          console.error(e)
        } finally {
          return true
        }
      }
    }
  }
}
