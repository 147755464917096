import {
  App,
  Component,
  createApp,
  h,
  getCurrentInstance,
  reactive,
  ComponentPublicInstance
} from 'vue'
import authenticDialog from './index.vue'

function mountComponent(RootComponent: Component) {
  const app = createApp(RootComponent)
  const root = document.createElement('div')

  document.body.appendChild(root)

  return {
    instance: app.mount(root),
    unmount() {
      document.body.removeChild(root)
      app.unmount()
    }
  }
}

let queue: ComponentPublicInstance[] = []

function createInstance() {
  const { instance, unmount } = mountComponent({
    setup() {
      const state = reactive({
        visible: false
      })
      const open = () => {
        state.visible = true
      }
      const close = () => {
        state.visible = false
        unmount()
      }
      const emit = {
        onResolve() {
          close()
          unmount()
        },
        onReject() {
          close()
          unmount()
        },
        onSuccess() {
          close()
        }
      }
      const render = () => {
        return h(authenticDialog, {
          ...state,
          ...emit
        })
      }
      // rewrite render function
      ;(getCurrentInstance() as any).render = render
      return {
        open,
        close
      }
    }
  })
  return instance
}

export function ShowPerfectRegister(options: any = {}) {
  let authentic: ComponentPublicInstance | null = null
  console.log(authentic)
  // if (queue.length) {
  //   signContract = queue[queue.length - 1]
  // } else {
  //
  // }
  authentic = createInstance()
  queue.push(authentic)
  console.log(queue)
  ;(authentic as any).open()
  return authentic
}
