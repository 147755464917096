import { defineStore } from 'pinia'
import { IExtendObj } from '../types'
import { getStoreDetail } from '@/api/ps'
import useShopStore from '@/store/store'

const useStoreDetailStore = defineStore({
  id: 'storeDetail',
  state: () => {
    return {
      storeDetail: {}
    } as { storeDetail: IExtendObj }
  },
  persist: true,
  actions: {
    async getStoreDetail() {
      const useShop = useShopStore()
      try {
        const { content } = await getStoreDetail({
          jsonData: { id: useShop?.store?.currentStore?.cusStoreId }
        })
        this.storeDetail = content
        // isConfirmOrder.value = Number(content.isConfirmOrder) === 1
      } catch (error) {}
    },
    removeStoreDetail() {
      this.storeDetail = {}
    }
  }
})
export default useStoreDetailStore
