import { post, get } from '@/utils/request'
import { EOfflineType, EPayType, IExtendObj } from '@/types'

const gate = '/api/mall-oc'

// 订单确认收货
export function mallOcBatchReceive(data: IExtendObj = {}) {
  return post<any>(`${gate}/mall_order/v1/batch_confirm_receipt`, {
    jsonData: data
  })
}

// 订单确认收货
export function mallOcReceive(data: IExtendObj = {}) {
  return post<any>(`${gate}/order_info/v1/confirm_receipt`, {
    jsonData: data
  })
}

// 校验库存
export function mallOcVerifyStorage(data: IExtendObj = {}) {
  return post<any>(
    `${gate}/oc_order_subscribe/v1/verify_storage_qty`,
    {
      jsonData: data
    },
    {
      isUnBatchErrorMessage: true
    }
  )
}

/**
 * 查询malloc购物车数量
 * @param data
 * @returns
 */
export function getMallOcSubscribeQty(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/get_order_subscribe_total_sku_qty `, data)
}

/**
 * 查询购物车列表
 * @param data
 * @returns
 */
export function queryMallOcSubscribeList(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/query_order_subscribe_list_for_pc`, data)
}

/**
 * 编辑购物车
 * @param data
 * @returns
 */
export function editMallOcSubscribe(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/update_order_subscribe`, data, {
    isUnErrorMessage: true
  })
}

/**
 * 购物车删除
 * @param data
 * @returns
 */
export function deleteMallOcSubscribe(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/delete_order_subscribe_for_pc`, data)
}

/*
  订单详情
*/
interface IQueryDetail {
  orderInfoId: string | number
}

export function queryDetail(data: IQueryDetail) {
  console.log(data)

  return post<any>(`${gate}/order_info/v1/select_order_info_details`, { jsonData: data })
}

/*
  取消订单
*/
interface ICancelOrder {
  orderInfoIds: number[]
  cancelReason: string | string[]
}

export function cancelOrder(data: object) {
  return post<any>(`${gate}/order_info/v1/cancel_order_info`, data)
}

export interface IOrderInfoVo {
  orderInfoId: number
  tradeOrderNo: string
  batchNo: null | number
  orderInfoDate: string
  createTime: string
  isSkinShipping: '0' | '1'
  isSkinShippingDesc: '否' | '是'
  status: number
  statusDesc: string
  payCheckStatus: number
  payCheckStatusDesc: string
  checkStatus: number
  orderCheckRemark: string
  settleType: number
  customerOrderCode: string
  customerRemark: string
  payCheckRefuse: string | null
  commodityMoney: string
  logisticsMoney: string
  receivableMoney: string
  submitFailedReason: string
  payCheckRefuseDesc: string
  afterSalesStatus: number
  isAllowAfterSales: number
  ocRefundOrderInfoId?: number
  isSubmitFailedReasonExpand?: boolean
  isRemarkExpand?: boolean
  isSkuExpand?: boolean
  isLogisticsExpand?: boolean
  isPayCheckRefuseExpand?: boolean
  isCustomerOrderCodeExpand?: boolean
  isNnInvoice?: string | number
  pcShowCommodityMoney: string | number
  isShowApplyInvoiceButton: boolean
}

interface IItemForPcVo {
  pictureUrl: string | undefined
  psMainImgUrl: string | undefined
  id: number
  ocOrderInfoId: number
  psSkuName: string
  psSkuCode: string
  unitPrice: string
  skuQty: number
  totalMoney: string
  pcShowUnitPrice: number | string
  pcShowTotalMoney: number | string
  psUnifySupplyPrice: number
}

interface IAddressVO {
  id: number
  ocOrderInfoId: number
  cusAddressInfoId: null
  cusReceiverProvinceId: number
  receiverProvince: string
  cusReceiverCityId: number
  receiverCity: string
  cusReceiverAreaId: number
  receiverArea: string
  receiverAddress: string
  encryptPhone: null | string
  receiverPhone: string
  receiverTomiPhone: null | string
  receiverName: string
  receiverZipCode: null | string
}

interface ILogisticsVo {
  id: number
  ocOrderInfoId: number
  expressNo: string
  expressCode: string
  expressName: string
  isSplit: null
  skuQty: null | number
  createTime: null | string
  createUserId: null | number
  createUserName: null | string
  outTime: string
  confirmStatus: string
}

interface ICapitalVO {
  id: number
  ocOrderInfoId: number
  fcPlatformAccountId: number
  fcPlatformAccountCode: string
  fcPlatformAccountName: string
  billNo: string
  amount: number
  currency: string
  remark: null | string
  availAmount: null | number
  useRatio: null | number
  useIntegral: number
  availIntegral: null | number
  payWay: string
  payWayDesc: string
  exchangeRate: number
}

export interface IOrderInfoBillVO {
  orderInfoVO: IOrderInfoVo
  itemForPcVOList: IItemForPcVo[]
  addressVO: IAddressVO
  logisticsVOList: ILogisticsVo[]
  capitalVOList: ICapitalVO[]
}

export interface IOrderInfoList {
  pageNum: number
  totalCount: number
  pageSize: number
  totalPage: 1
  data: {
    orderInfoBillVOList: IOrderInfoBillVO[]
    /**
     * 全部
     */
    total: number
    /**
     * 待提交
     */
    readyToCommit: number
    /**
     * 待确认
     */
    readyToAudit: number
    /**
     * 待支付
     */
    readyToPay: number
    /**
     * 待发货
     */
    readyToDelivery: number
    /**
     * 待收货
     */
    alreadyDelivery: number
    /**
     * 已经完成
     */
    finish: number
    /**
     * 已取消
     */
    cancel: number
    /**
     * 已失效
     */
    invalid: number
    /**
     * 提交失败数量
     */
    submitFailedCount: number
  }
}

export function queryOrderInfoList(data: IExtendObj) {
  return post<IOrderInfoList>(`${gate}/order_info/v1/list_for_pc`, data)
}

export function batchSubmitOrderInfo(data: IExtendObj) {
  return post<boolean>(`${gate}/order_info/v1/batch_submit_order_info`, {
    jsonData: { ...data }
  })
}

export function deleteBatchOrderInfo(orderInfoIds: number[]) {
  return post<boolean>(`${gate}/order_info/v1/delete_batch_order_info`, { orderInfoIds })
}

export interface IPayDetail {
  /**
   * 订单编号
   */
  tradeOrderNoList: string[]
  /**
   * 批次号
   */
  batchNoList: string[]
  /**
   * 订单价格
   */
  orderPrice: number
  /**
   * 剩余时间毫秒
   */
  payValidTime: number
  ocOrderInfoIdList: number[]
  /**
   * 支付凭证
   */
  fileUrlList: string[]
  /**
   * 支付流水
   */
  payCodeList: string[]
}

/**
 * 查询订单支付详情
 * @param data
 * @returns
 */
export function selectPaymentOrder(data: {
  jsonData: { ocOrderInfoIdList?: number[]; batchNoList?: string[] }
}) {
  return post<IPayDetail>(`${gate}/order_info/v1/select_payment_order`, data)
}

export function saveAndSubmit(data: object, isUnBatchErrorMessage: boolean = false) {
  return post<IExtendObj>(`${gate}/order_info/v2/save_and_submit`, data, {
    isUnErrorMessage: true,
    isUnBatchErrorMessage
  })
}

export function placeOrder(data: object, isUnBatchErrorMessage: boolean = false) {
  return post<IExtendObj>(`${gate}/order_info/v2/place_order_by_self`, data, {
    isUnErrorMessage: true,
    isUnBatchErrorMessage
  })
}

/**
 * 识别图片
 * @param data
 * @returns
 */
export function getOcrDetails(url: string) {
  return post<string>(`${gate}/order_payment_info/v1/get_ocr_details`, { jsonData: url })
}

interface IPayCreateParams {
  ocOrderInfoIdList: number[]
  payType: EPayType
  isMini: boolean
  offlineType?: EOfflineType
  fileUrlList?: string[]
  payCodeList?: string[]
}

/**
 * 发起支付
 * @param data
 * @returns
 */
export function createPayOrder(jsonData: IPayCreateParams) {
  return post<{ ocOrderInfoPaymentInfoId: number; content: string; payValidTime: number }>(
    `${gate}/order_payment_info/v1/create_pay_order`,
    {
      jsonData
    },
    {
      isUnErrorMessage: true
    }
  )
}

interface IBatchParams {
  jsonData: IExtendObj
}

/**
 * 查询批次号
 * @param data
 * @returns
 */
export function selectBatchNo(data?: IBatchParams) {
  return post<number[]>(`${gate}/order_info/v1/select_batch_no`, data)
}

/**
 * 轮询支付
 * @param data
 * @returns
 */
export function selectPayOrder(dataId: number) {
  return post<{ status: number; payMoney: number; tradeStateDesc: string }>(
    `${gate}/order_payment_info/v1/select_pay_order`,
    {
      dataId
    }
  )
}

/**
 * 提交失败订单
 * @param data
 * @returns
 */
export function submitFailedOrder() {
  return post<{ status: number; payMoney: number; tradeStateDesc: string }>(
    `${gate}/order_info/v1/submit_failed_order`,
    {}
  )
}

/**
 * 退款申请-前置查询
 * @param data
 * @returns
 */
export function getMallOrderRefundInfo(data: { jsonData: { orderInfoIds: number[] } }) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/query_order_for_refund`, data)
}

/**
 * 提交退款申请（批量）
 * @param data
 * @returns
 */
export function submitRefundMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v2/submit_refund_order_for_pc`, data)
}

/**
 * 提交退款申请（单个）
 * @param data
 * @returns
 */
export function submitSingleRefundMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/submit_refund_order_single`, data)
}

/**
 * 获取退款申请详情
 * @param data
 * @returns
 */
export function getRefundDetailMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/query_refund_order_detail`, data)
}

/**
 * 获取售后商品明细/赠品明细
 * @param data
 * @returns
 */
export function getAfterSaleProductAndGiftMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_order_info_items/v1/select_goods_and_gift_by_order_id`, data)
}

/**
 * 提交售后申请
 * @param data
 * @returns
 */
export function submitAfterSaleMallOrder(data: IExtendObj) {
  return post<any>(`${gate}/oc_sales_return/v1/submit_sales_return_for_pc`, data)
}

/**
 * 提交售后申请保存前校验
 * @param data
 * @returns
 */
export function submitAfterSaleCheckMallOrder(data: IExtendObj) {
  return post<any>(`${gate}/oc_sales_return/v1/submit_sales_return_check`, data)
}

/**
 * 获取关联售后
 * @param data
 * @returns
 */
export function selectSalesReturnList(data: IExtendObj) {
  return post<IExtendObj[]>(`${gate}/oc_sales_return/v1/select_sales_return_list`, data)
}

/**
 * 获取售后详情
 * @param data
 * @returns
 */
export function getSalesReturnDetailMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_sales_return/v1/query_sale_return_detail_for_pc`, data)
}

/**
 * 取消售后
 * @param data
 * @returns
 */
export function cancelSalesReturnMallOrder(params: IExtendObj) {
  return get<IExtendObj>(
    `${gate}/oc_sales_return/v1/cancel_sales_return?salesReturnId=${params.salesReturnId}`,
    {}
  )
}

/**

 * 查询售后列表
 * @param data
 * @returns
 */
export function selectSalesPageMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_sales_return/v1/select_sales_page`, data)
}

/**
 * 查询售后列表数量
 * @param data
 * @returns
 */
export function selectSalesTableCountMallOrder(data: IExtendObj) {
  return post<string[]>(`${gate}/oc_sales_return/v1/select_sales_table_count`, data)
}

/**
 * 更新退货物流
 * @param data
 * @returns
 */
export function updateLogisticsMallOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_sales_return/v1/update_sales_return_logistics`, data)
}

/**
 * 获取售后详情物流轨迹
 * @param data
 * @returns
 */
export function getLogisticsListMallOrder(data: IExtendObj) {
  return post<IExtendObj[]>(`${gate}/oc_order_info_logistics/v1/query_logistics_trajectory`, data)
}

/**
 *订单复制新增 获取获取详情
 * @param data
 * @returns
 */
export function getOrderCopyDetail(data: IExtendObj) {
  return get<IExtendObj[]>(
    `${gate}/order_info/v1/query_order_by_copy_for_pc?orderInfoId=${data.orderInfoId}&isPc=${data.isPc}`,
    {}
  )
}

/**
 *订单撤销提交
 * @param ids
 * @returns
 */
export function cancelConfirm(ids: (number | string)[]) {
  return post<IExtendObj>(`${gate}/mall_order/v1/batch_un_submit_mall_order`, { jsonData: { ids } })
}

/**
 *订单撤销线下支付
 * @param ids
 * @returns
 */
export function cancelPay(ids: (number | string)[]) {
  return post<IExtendObj>(`${gate}/mall_order/v1/batch_repeal_off_line_mall_order`, {
    jsonData: { ids }
  })
}

/**
 * 一键加入购物车
 * @returns Promise
 */
export function saveMallOrderSubscribe(data: IExtendObj) {
  return post<any>(`${gate}/oc_order_subscribe/v2/save_order_subscribe`, data)
}

/**
 * 客户订单号
 * @param data
 * @returns
 */
export function selectByCustomerOrderCode(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/order_info/v1/query_customer_order_code_is_exist`, data)
}

/**
 * 接单发票列表
 * @param data
 * @returns
 */
export function selectInvoiceList(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/invoice/v1/select_invoice_order_page`, data)
}

/**
 * 计算运费
 */
export function calculateTransFee(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/order_info/v2/calculate_trans_fee`, data)
}

/**
 * 开票校验
 */
export function checkMallInvoice(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/invoice/v1/check_invoice`, data)
}

/**
 * 再次购买校验
 */
export function checkAddCart(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_order_info_items/v1/order_info_buy_again_check`, data)
}

/**
 * 再次加入购买车
 */
export function addMallCart(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_order_subscribe/v1/order_info_buy_again`, data)
}

/**
 * 发票模块可开票列表数量统计
 */
export function applyInvoiceMallTotal(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/invoice/v1/select_invoice_order_page_sum`, data)
}

/**
 * 获取批量仅退款提交后退款单展示详情
 */
export function batchMallRefundDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/query_batch_result`, data)
}
