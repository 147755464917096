import { defineStore } from 'pinia'
import { customerInfo, select_contract_page } from '@/api/cus'
import useMenuStore from './menu'
import { EOrderType } from '@/types'
import useShopStore from '@/store/store'
import useStoreDetailStore from '@/store/storeDetail'
import { storeValidate } from '@/utils/store'
import { IExtendObj } from '../types'

interface ICustomerResult extends IExtendObj {
  authenticationStatus: string | null // 是否认证 1-未认证 2-已认证
  approvalStatus: string | number | null // 审核状态 1.未审核 2.已审核
  authenticationMethod: string | null // 认证方式 1-手工认证 2-线上认证
  isActivation: string // 是否激活 0-否 1是
  signContractStatus: string // 签署状态 1-未签署 2-已签署 3 签署中 4-签署失败
  status: string | null // 1未激活&无销货性客户审批单状态、2-未审核&有销货性客户审批单状态
  purchaseModeList: EOrderType[] // 采购模式(可多选，逗号分隔):1-批量采购,2-一件代发
  auditFailReason: string // 认证失败原因
  signContractReason: string // 签署合同失败原因
  companyName: string
  cusCertificationInfoId: string // 实名认证id
  customerSpuHabit: string // 客户切换视图
  customerLastOperate: string // 客户切换采购模式
  customerType: number // 客户账号类型 1:个人2:企业
  notSignContractCount: number // 合同补充协议总数
  notSignContractList: IExtendObj[] // 合同补充协议列表
}

const useCustomerStore = defineStore({
  id: 'customer',
  state: () => {
    return {
      customer: undefined
    } as { customer?: ICustomerResult }
  },
  persist: true,
  actions: {
    async getCustomerInfo(form: any = '', isSelectStore: boolean = true) {
      const useShop = useShopStore()
      const useStoreDetail = useStoreDetailStore()
      if (!useShop.store?.currentStore && !useShop.isSuccess) return
      const isOpen: boolean = ['Register', 'Login', 'changeStore'].includes(form)
      let res
      if (form === 'Register') {
        // 从注册页面进来点击刷新状态之后需要先判断客户的状态，客户状态为已审核的情况下弹出店铺选择框（注册入口的客户都是自营入口，不考虑内购情况）
        res = await customerInfo({ jsonData: { isQueryContract: 1 } })
        if (Number(res.content.approvalStatus) === 2) {
          await useShop.getShop()
          await storeValidate(isOpen)
        }
      } else {
        // 从别的入口进来之后则先选择店铺之后进入后再判断客户状态（因为会有内购、自营的情况需要区分）
        if (isSelectStore) {
          await storeValidate(isOpen)
        }
        res = await customerInfo({ jsonData: { isQueryContract: 1 } })
      }
      const { content } = res
      this.customer = content
      await useMenuStore().handleMenu()
      await useStoreDetail.getStoreDetail()
    },
    removeCustomerInfo() {
      this.customer = undefined
    },
    updateCustomerInfo(customer: ICustomerResult) {
      this.customer = customer
    },
    updateCustomerInfoByKey(key: string, value: any) {
      if (!this.customer) return
      this.customer[key] = value
    }
  }
})
export default useCustomerStore
