import {
  App,
  Component,
  createApp,
  h,
  getCurrentInstance,
  reactive,
  ComponentPublicInstance
} from 'vue'
import selectAuthenticationDialog from './index.vue'

function mountComponent(RootComponent: Component) {
  const app = createApp(RootComponent)
  const root = document.createElement('div')

  document.body.appendChild(root)

  return {
    instance: app.mount(root),
    unmount() {
      app.unmount()
      document.body.removeChild(root)
    }
  }
}

let queue: ComponentPublicInstance[] = []

function createInstance() {
  const { instance, unmount } = mountComponent({
    setup() {
      const state = reactive({
        visible: false,
        type: 1
      })
      const open = (options: { type: number }) => {
        state.visible = true
        state.type = options.type || 1
      }
      const close = () => {
        state.visible = false
        unmount()
      }
      const emit = {
        onResolve() {
          close()
          unmount()
        },
        onReject() {
          close()
          unmount()
        },
        onSuccess() {
          close()
        }
      }
      const render = () => {
        return h(selectAuthenticationDialog, {
          ...state,
          ...emit
        })
      }
      // rewrite render function
      ;(getCurrentInstance() as any).render = render
      return {
        open,
        close
      }
    }
  })
  return instance
}

export function ShowSelectAuthenticationDialog(options: any = {}) {
  let authentic: ComponentPublicInstance | null = null
  console.log(authentic)
  authentic = createInstance()
  queue.push(authentic)
  console.log(queue)
  ;(authentic as any).open(options)
  return authentic
}
