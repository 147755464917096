import { defineStore } from 'pinia'
import { IExtendObj } from '@/types'
import { getAllCity, selectAllCity } from '@/api/mdm'
import _ from 'lodash'

const useAddressStore = defineStore({
  id: 'address',
  state: () => {
    return {
      address: []
    } as { address?: IExtendObj[] }
  },
  // persist: {
  //   // 修改存储中使用的键名称，默认为当前 store的 id
  //   key: '__address__',
  //   // 修改为 sessionstorage，默认为 localstorage
  //   storage: sessionStorage
  // },
  actions: {
    async getAddress() {
      const { content } = await selectAllCity()
      this.address = content
    }
  }
})
export default useAddressStore
