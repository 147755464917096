<template>
  <el-dialog v-model="visible" title="请选择进入的店铺" width="320px" :before-close="handleClose">
    <el-radio-group v-model="storeId">
      <el-radio
        v-for="item in userStoreList"
        :key="item.cusStoreId"
        :disabled="item.disabled"
        :label="item.cusStoreId"
        >{{ item.storeName }} <span v-if="item.disabled">（当前登录）</span></el-radio
      >
      <br />
    </el-radio-group>
    <template #footer>
      <el-button type="primary" size="small" @click="handleConfirm"> 确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { ElMessage } from 'element-plus'
  import useShopStore from '@/store/store'

  export default {
    name: 'StoreDialog',
    data() {
      return {
        visible: false,
        storeId: null,
        userStoreList: []
      }
    },
    methods: {
      resolve(data) {
        this.$emit('resolve', data)
      },
      reject(data) {
        this.$emit('reject', data)
      },
      handleConfirm() {
        console.log(this.storeId)
        if (!this.storeId) {
          ElMessage.warning('请选择店铺')
          return
        }
        this.visible = false
        const store = this.userStoreList.filter(
          (item) => Number(item.cusStoreId) === Number(this.storeId)
        )
        this.resolve({ currentStore: store[0] })
      },
      show() {
        const useShop = useShopStore()
        if (useShop.store?.currentStore) {
          this.storeId = useShop.store?.currentStore?.cusStoreId
        }
        this.visible = true
        console.log(this.userStoreList, this.storeId)
      },
      handleClose() {
        this.visible = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep {
    .el-radio {
      display: flex;
    }
  }
</style>
