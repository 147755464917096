<template>
  <yf-dialog
    class="login-dialog"
    :visible="props.show"
    title=""
    width="360px"
    :button-list="[]"
    notShowClose
  >
    <i class="el-icon circle-close" style="font-size: 18px" @click="handleClose">
      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
        <path
          fill="currentColor"
          d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
        ></path>
      </svg>
    </i>
    <login-component @login-success="handleLoginSuccess" :is-open-dialog="true" />
  </yf-dialog>
</template>
<script setup lang="ts">
  import LoginComponent from './index.vue'
  interface IProps {
    show: boolean
  }
  const props = defineProps<IProps>()
  interface ILoginComponentEmits {
    (e: 'login-success', isNewUser: boolean): void
    (e: 'close'): void
  }
  const emit = defineEmits<ILoginComponentEmits>()
  function handleLoginSuccess(isNewUser: boolean) {
    emit('login-success', isNewUser)
  }
  function handleClose() {
    emit('close')
  }
</script>
<style lang="scss">
  .login-dialog.yf-dialog {
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer,
    .notice-login,
    .el-dialog__header {
      display: none;
    }
    .login-content_wrap {
      margin-top: 0;
    }
    .circle-close {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 90;
      cursor: pointer;
      color: #c3c3c3;
    }
  }
</style>
