<template>
  <div class="notice" />
</template>

<script>
  import { defineComponent, onMounted, onBeforeUnmount } from 'vue'
  import { ElMessageBox } from 'element-plus'
  import { selectMaintenances } from '@/api/sc'
  export default defineComponent({
    setup(props, ctx) {
      const getMaintenances = async () => {
        const { content: list } = await selectMaintenances()
        if (list.length) {
          const content = list[0]
          const tipStartTime = new Date(content.tipStartTime).getTime()
          const tipEndTime = new Date(content.tipEndTime).getTime()
          const selfTime = Date.now()
          if (content.haltSwitch) {
            ElMessageBox.alert(content.tipContent, '系统公告', {
              lockScroll: true,
              showClose: false,
              showCancelButton: false,
              showConfirmButton: false
            })
          } else if (selfTime > tipStartTime && selfTime < tipEndTime) {
            if (!localStorage.getItem(tipStartTime)) {
              localStorage.setItem(tipStartTime, true)
              ElMessageBox.confirm(content.tipContent, '系统公告', {
                lockScroll: true,
                showClose: false,
                showCancelButton: false
              })
            }
          } else {
            ElMessageBox.close()
            localStorage.removeItem(tipStartTime)
          }
        }
      }
      let timer = null
      onMounted(() => {
        getMaintenances()
        timer = setInterval(() => {
          getMaintenances()
        }, 5000 * 60)
      })
      onBeforeUnmount(() => {
        timer && clearInterval(timer)
      })
    }
  })
</script>

<style></style>
