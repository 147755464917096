import { post, get } from '@/utils/request'
import { IExtendObj } from '@/types'
import useNotLogin from '@/hooks/useNotLogin'
const gate = '/api/cus'

// api/cus/customer_address/v1/save_customer_address

// 客户收货地址列表查询：api/cus/customer_address/v1/query_customer_address_list
// 入参：{
//   "cusCustomerId": 212
// }
// 客户收货地址吸怪：api/cus/customer_address/v1/update_customer_address
// 入参：
// {
//   "cusCustomerId": 212,
//   "receiverName": "测试一下",
//   "receiverPhone": "12343234543",
//   "provinceId": 1,
//   "province": "北京",
//   "cityId": 3216,
//   "city": "北京市",
//   "areaId": 2,
//   "area": "东城区",
//   "address": "0001号001",
//   "isDefault": 0,
//   "id": 213
// }

// 客户发货地址新增：api/cus/customer_address_sender/v1/save_customer_address
// 客户发货地址修改：api/cus/customer_address_sender/v1/update_customer_address
// 客户发货地址列表：api/cus/customer_address_sender/v1/query_customer_address_list

// 收货地址列表查询
export function getAddressList(data: object = {}) {
  return post<IExtendObj[]>(`${gate}/customer_address/v1/query_customer_address_list`, data)
}

// 新增收货地址
export function saveAddress(data: object) {
  return post<IExtendObj>(`${gate}/customer_address/v1/save_customer_address`, data)
}

// 编辑收货地址
export function updateAddress(data: object) {
  return post<IExtendObj>(`${gate}/customer_address/v1/update_customer_address`, data)
}

// 收货地址批量删除
export function delAddress(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_address/v1/batch_delete_customer_address`, data)
}

// 发货地址列表查询
export function getSenderAddressList(data: object = {}) {
  return post<IExtendObj[]>(`${gate}/customer_address_sender/v1/query_customer_address_list`, data)
}

// 新增发货地址
export function saveSenderAddress(data: object) {
  return post<IExtendObj>(`${gate}/customer_address_sender/v1/save_customer_address`, data)
}

// 编辑发货地址
export function updateSenderAddress(data: object) {
  return post<IExtendObj>(`${gate}/customer_address_sender/v1/update_customer_address`, data)
}

// 发货地址批量删除
export function delSenderAddress(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_address_sender/v1/batch_delete_customer_address`, data)
}

// 获取小程序二维码
export function getCode(data: { jsonData: number | undefined }) {
  return post<any>(`${gate}/certification_info/v1/get_mini_qr_code`, data)
}

// 客户信息查询发票
export function queryCustomerInvoice(data: IExtendObj) {
  return post<any>(`${gate}/customer_invoice/v1/query_customer_invoice_list`, data)
}

// 客户信息设置发票默认值
export function updateInvoiceDefault(data: IExtendObj) {
  return post<any>(`${gate}/customer_invoice/v1/update_invoice_default`, data)
}

// 客户信息 删除发票
export function deleteCustomerInvoice(data: IExtendObj) {
  return post<any>(`${gate}/customer_invoice/v1/delete_customer_invoice`, data)
}

// 客户信息添加发票
export function saveCustomerInvoice(data: IExtendObj) {
  return post<any>(`${gate}/customer_invoice/v1/save_customer_invoice`, data)
}

// 客户信息修改发票
export function updateCustomerInvoice(data: IExtendObj) {
  return post<any>(`${gate}/customer_invoice/v1/update_customer_invoice`, data)
}

// 获取客户信息状态
export function customerInfo(data: IExtendObj) {
  return useNotLogin(() => post<any>(`${gate}/customer/v1/get_customer_status`, data), {
    content: null
  })
}

// 个人实名认证
export function getPersonVerify() {
  return post<any>(`${gate}/certification_info/v1/get_person_verify_url`, {})
}

// 企业实名认证
export function getCompanyVerify() {
  return post<any>(`${gate}/certification_info/v1/get_company_verify_url`, {})
}

// 账号完善信息
export function saveCusInfo(data: IExtendObj) {
  return post<any>(`${gate}/customer_approval/v1/save_approval_by_cus_register`, data)
}

// 预览合同
export function contractPreview(data: IExtendObj) {
  return post<any>(`${gate}/contract/v1/get_contract_preview_url`, data)
}

// 签署合同
export function getSignUrl(data: IExtendObj) {
  return post<any>(`${gate}/contract/v1/get_sign_url`, data)
}

interface IDetailProps {
  user: {
    customerType: '1' | '2'
    userName: string
    phone: string
    sensitivePhone: string
    avatarUrl: string
  }
  customer: {
    address: string
    provinceId: string | number
    cityId: string | number
    province: string
    city: string
    purchaseModeList: ('1' | '2')[]
    customerName?: string
    availPoint: number
    customerRole: string
  }
  certification: {
    personName: string
    idCard: string
    sensitiveIdCard: string
    companyName: string
    organization: string
    sensitiveOrganization: string
  }
  contract: {
    code: string
    signStatus: 0 | 1 | 2 | 3
    effectiveTimeStart: number
    effectiveTimeEnd: number
    downloadUrl: string
    contractTemplateName: string
  }
  payments: IExtendObj[]
}

// 获取个人信息
export function userDetail(data?: number) {
  return post<IDetailProps>(`${gate}/customer/v1/query_user_detail`, { jsonData: data })
}

// 修改办公地址
export function editAddress(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer/v1/update_customer_address`, {
    jsonData: data
  })
}

// 公司名称校验
export function checkCompany(customerName: string) {
  return post<IExtendObj>(`${gate}/customer/v1/check_company_name_exist`, {
    jsonData: { customerName }
  })
}

// 注册协议
export function getContractContent(data: number) {
  return post<string>(
    `${gate}/contract/v1/get_contract_content`,
    {
      jsonData: data
    },
    { isUnToken: true }
  )
}

// 获取认证二维码
export function getCertificationCode(data: IExtendObj) {
  return post<string>(`${gate}/certification_info/v1/get_mini_qr_code`, {
    jsonData: data
  })
}

// 获取客户店铺
export function getStore(data: IExtendObj) {
  return post<IExtendObj[]>(`${gate}/customer_store/v1/query_customer_store_list`, data)
}

// 店铺品牌预览/下载
export function getAuthPreview(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_store_auth_brand/v1/select_by_id`, data)
}

// 店铺授权申请单
export function applyBrandAuth(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_store_auth/v1/apply_brand_auth`, data)
}

// 获取店铺详情
export function getStoreDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_store/v1/query_customer_store`, data)
}

// 客户店铺新增
export function saveCustomerStore(data: IExtendObj) {
  return post<any>(`${gate}/customer_store/v1/save_customer_store`, data)
}

// 修改店铺信息
export function updateCustomerStore(data: IExtendObj) {
  return post<any>(`${gate}/customer_store/v1/update_customer_store`, data)
}

// 授权书列表
export function authList(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_store_auth_brand/v1/select_page`, data)
}

// 店铺删除
export function deleteStore(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/customer_store/v1/delete_customer_store`, data)
}

// 获取实名列表
export function getCertificationInfo(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/certification_info/v1/find_by_customer`, data)
}

// 设置默认地址
export function getDefaultAddress(data: IExtendObj) {
  return post<any>(`${gate}/customer_address/v1/update_address_default`, data)
}

// 设置视图
export function selectViewSwitch(data: IExtendObj) {
  return useNotLogin(() => post<any>(`${gate}/customer_habit/v1/save_spu_habit`, data), {
    content: null
  })
}

// 合同补充协议列表
export function select_contract_page(data: IExtendObj) {
  return post<any>(`${gate}/contract/v1/select_contract_page`, data)
}
// 品牌授权书数量
export function select_store_count(data: IExtendObj) {
  return post<any>(`${gate}/customer_store_auth_brand/v1/select_store_count`, data)
}

// 店铺品牌撤销申请
export function getAuthRejection(data: IExtendObj) {
  return post<any>(`${gate}/customer_store_auth/v1/cancel_auth`, data)
}
// 修改客户名称
export function updateCustomerName(data: IExtendObj) {
  return get<any>(`${gate}/customer/v1/update_customer_name`, data)
}
// 客户名称唯一性校验
export function checkCustomerNameUnique(data: IExtendObj) {
  return get<any>(`${gate}/customer/v1/check_customer_name_unique`, data, {
    isUnErrorMessage: true
  })
}
// 获取客户名称修改次数
export function getCustomerNameModifiableCount(data: IExtendObj = {}) {
  return get<any>(`${gate}/customer/v1/get_customer_name_modify_time`, data)
}
