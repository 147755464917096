<template>
  <div v-if="mode === 'card'" class="mode-card">
    <div v-for="l in list" :key="l.id" class="mode-card__item">
      <div class="mode-card__item-img-wrap" :class="{ error: getErrorStr(l) }">
        <yf-image
          class="mode-card__item-img"
          v-model="l.logoUrl"
          nullImgSrcShowDefault
          :thumbnail="false"
          isPublic
          fit="contain"
        />
        <el-icon class="mode-card__item-img-close cursor-p" @click="handleClose(l)">
          <Close />
        </el-icon>
      </div>
      <!--      <NameComp :name="l.name" :englishName="l.englishName"></NameComp>-->
      <div style="text-align: center; margin: 4px 10px">
        <LineClamp mode="hover" :lineClamp="1" :lineHeight="17" :style="{ flex: 1 }">
          <p class="mode-card__item-name">{{
            `${l.name}${l.englishName ? ' / ' + l.englishName : ''}`
          }}</p>
        </LineClamp>
      </div>
      <div v-if="errorList && errorList.length" class="error-msg">
        <el-icon v-show="getErrorStr(l)"><Warning /></el-icon>{{ getErrorStr(l) }}
      </div>
    </div>
  </div>
  <div v-else class="mode-tag">
    <div v-for="l in list" :key="l.id" class="tag">
      <el-tag v-bind="$attrs" :class="props.disabled && 'tag-disable'" @close="handleClose(l)">{{
        l.name
      }}</el-tag>
      <div v-if="errorList && errorList.length" class="error-msg">
        <el-icon><Warning /></el-icon>{{ getErrorStr(l) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, computed } from 'vue'
  import type { IExtendObj } from '@/types'
  import NameComp from './name.vue'
  import LineClamp from '@/components/LineClamp/index.vue'

  interface IYfTagProps {
    modelValue: IExtendObj[]
    disabled?: boolean
    mode: undefined | 'tag' | 'card'
    errorList?: IExtendObj[]
  }
  /**
   * 自定义事件
   */
  interface IYfTagEmits {
    (e: 'update:modelValue', value: IExtendObj[]): void
    (e: 'close', value: IExtendObj[]): void
  }
  const props = defineProps<IYfTagProps>()
  const emit = defineEmits<IYfTagEmits>()
  const list = computed({
    get: () => props.modelValue,
    set: (val) => {
      emit('update:modelValue', val)
    }
  })
  const handleClose = (tag: IExtendObj) => {
    const res = list.value.filter((item) => item.id !== tag.id)
    emit('close', res)
    emit('update:modelValue', res)
  }

  const getErrorStr = (item: IExtendObj) => {
    const errorItem = props.errorList?.find((error) => Number(item.id) === Number(error.id))
    return errorItem?.errorMsg || ''
  }
</script>
<style lang="scss" scoped>
  .mode-card {
    display: flex;
    flex-wrap: wrap;
    .mode-card__item {
      border-radius: 2px;
      margin: 0 10px 10px 0;
      width: 140px;
    }
    .mode-card__item-img-wrap {
      border: 1px solid #dddddd;
      position: relative;
      &.error {
        border-color: #fa5555;
      }
    }
    .mode-card__item-img {
      width: 140px;
      height: 86px;
    }
    .mode-card__item-img-close {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      &:hover {
        color: var(--el-color-primary);
      }
    }
    .mode-card__item-title {
      font-size: 12px;
      color: #333333;
      line-height: 17px;
      padding: 0 10px;
      margin: 4px 0;
    }
  }
  .mode-tag {
    .tag {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      :deep(.el-tag--large.is-closable) {
        padding-right: 15px;
        min-width: 160px;
        position: relative;
      }
      :deep(.el-tag__content) {
        margin-right: 15px;
      }
      :deep(.el-tag__close) {
        position: absolute;
        right: 10px;
      }
    }
  }
  .mode-card__item-name {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
  .error-msg {
    font-size: 12px;
    font-weight: 400;
    color: #fa5555;
    line-height: 17px;
    display: flex;
    padding: 0 5px;
    :deep(.el-icon) {
      font-size: 14px;
      margin-right: 4px;
      margin-top: 2px;
      line-height: 17px;
    }
  }
</style>
