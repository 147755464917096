<template>
  <div class="authentication-contarct-dialog">
    <el-dialog
      v-model="props.visible"
      title="实名认证"
      width="540px"
      :close-on-click-modal="false"
      :beforeClose="beforeClose"
    >
      <div class="content">
        <svg-icon icon-class="sign" :size="50" />
        <div class="text"
          >您已注册成功，请等待平台审核，平台审核通过前可能会影响您下单及部分功能的体验</div
        >
      </div>
      <template #footer>
        <span class="contract-dialog-footer">
          <el-button type="primary" @click="toConfirm">我知道了</el-button>
          <!-- <span class="desc-btn" @click="toGoods">跳过，先逛逛</span> -->
        </span>
        <div v-if="Number(userStore.user?.customerType) === 2" class="company-tip">
          <el-icon color="#d00000" size="14px">
            <Warning />
          </el-icon>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts" setup>
  import { ref } from 'vue'
  import router from '@/router/index'
  import useUserStore from '@/store/user'
  import useCustomerStore from '@/store/customer'
  import { IExtendObj } from '@/types'
  import { ShowAuthenticTypeDialog } from '@/components/authenticationTypeDialog/authenticationTypeDialog'

  interface IProps {
    visible: boolean
  }

  interface IEmits {
    (e: 'resolve', params: IExtendObj): void

    (e: 'reject', params: IExtendObj): void

    (e: 'success'): void
  }

  const emit = defineEmits<IEmits>()
  const props = defineProps<IProps>()

  const userStore = useUserStore()
  const useCustomer = useCustomerStore()

  const visible = ref<boolean>(false)

  const resolve = (data: IExtendObj) => {
    emit('resolve', data)
  }

  const reject = (data: IExtendObj) => {
    emit('reject', data)
  }

  const beforeClose = () => {
    emit('success')
    resolve({ to: 'close' })
  }
  const toConfirm = async () => {
    // await ShowAuthenticTypeDialog({ type: 1 })
    resolve({ to: 'toConfirm' })
    emit('success')
  }

  const toGoods = () => {
    emit('success')
    /** 当前在商品详情页，不跳转 */
    if (router.currentRoute.value.name !== 'GoodDetail') {
      router.push({ path: '/stock/fast' })
    }
    resolve({ to: 'toGoods' })
  }
</script>
<style lang="scss" scoped>
  .authentication-contarct-dialog {
    .content {
      display: flex;

      .text {
        font-size: 16px;
        margin-left: 10px;
        line-height: 24px;
      }
    }

    :deep(.el-dialog) {
      border-radius: 16px;
    }

    :deep(.el-dialog__footer) {
      text-align: center;
    }

    .desc-btn {
      color: rgb(72, 67, 67);
      display: inline-block;
      margin-left: 8px;
      cursor: pointer;
    }

    .company-tip {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      .el-icon {
        margin-right: 8px;
      }
    }
  }
</style>
