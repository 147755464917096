function funs(
  tbody: {
    firstChild: {
      children: string | any[]
      firstChild: { setAttribute: (arg0: string, arg1: number) => void }
      cloneNode: (arg0: boolean) => any
    }
    children: { [x: string]: any }
    appendChild: (arg0: any) => void
    removeChild: (arg0: any) => void
  },
  value: {
    index: any
    extendSummaryConfig: any[]
    summaryColumns: any[]
    summaryConfig: { columns: string | any[] }
    tableColumn: any[]
  }
) {
  if (tbody && tbody.firstChild && tbody.firstChild.children && tbody.firstChild.children.length) {
    // 存在序号的情况下，合并单元格
    if (value.index) {
      tbody.firstChild.firstChild.setAttribute('colspan', 2)
      tbody.firstChild.children[1] &&
        tbody.firstChild.children[1].setAttribute('style', 'display: none;')
    }
    const trs = document.querySelectorAll('.el-table__footer-wrapper .el-table__footer tr.tr-one')
    trs[0]?.firstChild?.firstChild?.setAttribute('style', 'width: 100px')
    trs[1]?.firstChild?.firstChild?.setAttribute('style', 'width: 100px')
    if (value.extendSummaryConfig && value.extendSummaryConfig.length) {
      if (value.extendSummaryConfig.length < trs.length) {
        trs[trs.length - 1] && tbody.removeChild(trs[trs.length - 1])
      }
      value.extendSummaryConfig.forEach((extend: { data: any; title: string }, i: number) => {
        let tr: { children: { children: { innerHTML: any }[] }[] } | null = null
        const selfElement = tbody.children[i + 1]
        if (selfElement) {
          tr = selfElement
        } else {
          tr = tbody.firstChild.cloneNode(true)
        }
        if (!tr?.children[0].children[0]) {
          return
        }
        value.summaryColumns.forEach((column, i) => {
          const isSummary = value.summaryConfig.columns.includes(column.property)
          const data = extend.data
          tr.children[i].children[0].innerHTML = ' '
          if (column.property && Reflect.has(data, column.property) && isSummary) {
            const cell = value.tableColumn.find((c: { prop: any }) => c.prop === column.property)
            tr.children[i].children[0].innerHTML = data[column.property]
          }
        })
        tr.children[0].children[0].innerHTML = extend.title || '总合计'
        tr?.setAttribute('class', 'tr-one')
        if (!selfElement) {
          tbody.appendChild(tr)
        }
      })
    } else {
      const tr = document.querySelector('.el-table__footer-wrapper .el-table__footer tr.tr-one')
      tr && tbody.removeChild(tr)
    }
  }
}
import { App } from 'vue'
export default {
  install(app: App<Element>) {
    app.directive(
      'TableSummaryExtend',
      (
        el: { querySelector: (arg0: string) => any; querySelectorAll: (arg0: string) => any },
        binding: { value: any }
      ) => {
        const { value } = binding
        // console.log('extendSummaryConfig', value)
        if (value && value.showSummary && value.summaryColumns.length) {
          // 合计行dom对象
          const tbody = el.querySelector('.el-table__footer-wrapper .el-table__footer tbody')
          // 合计行固定行dom对象
          const fixedTbody = el.querySelectorAll(
            '.el-table__fixed-footer-wrapper .el-table__footer tbody'
          )
          setTimeout(() => {
            // console.log('extendSummaryConfig', value)
            funs(tbody, value)
            funs(fixedTbody[0], value)
            funs(fixedTbody[1], value)
            setTimeout(() => {
              try {
                value.doLayout()
              } catch (error) {
                //
              }
            }, 200)
          }, 200)
        }
      }
    )
  }
}
