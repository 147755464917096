<template>
  <el-checkbox-group v-model="value" @change="handleChange">
    <el-checkbox v-for="item in props.options" :key="item.value" :label="item.value">{{
      item.label
    }}</el-checkbox>
  </el-checkbox-group>
</template>
<script setup lang="ts">
  import { ref, computed } from 'vue'
  import type { IExtendObj } from '@/types'

  interface IYfCheckProps {
    modelValue: string[] | number[]
    disabled?: boolean
    options: IExtendObj[]
  }
  /**
   * 自定义事件
   */
  interface IYfCheckEmits {
    (e: 'update:modelValue', value: string[] | number[]): void
  }
  const props = defineProps<IYfCheckProps>()
  const emit = defineEmits<IYfCheckEmits>()
  const value = computed({
    get: () => props.modelValue,
    set: (val) => {
      emit('update:modelValue', val)
    }
  })
  const handleChange = (val: string[] | number[]) => {
    emit('update:modelValue', val)
  }
</script>
<style lang="scss"></style>
