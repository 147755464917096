import { defineStore } from 'pinia'
import { IExtendObj } from '@/types'
import useShopStore from '@/store/store'
import {
  queryHomePageRenovationInfo,
  queryPageRenovationInfo,
  queryGoodsGroup,
  queryBrandGroupPage
} from '@/api/mdm'
import { selectGoodsBySkuList, queryBrandPageForPc } from '@/api/ps'
import { DecorateCommon, DecoratePC } from '@yf-web/decorate'
import { cloneDeep } from 'lodash'

interface IDecorateConfig {
  content?: string
  createTime?: string
  createUserId?: number
  createUserName?: string
  homeStatus?: number
  id?: number
  is_delete?: number
  name?: string
  ownerUserId?: number
  ownerUserName?: string
  purchaseMode?: number
  renovationId?: null
  renovationType?: number
  shopId?: number
  shopName?: string
  sysCompanyId?: number
  sysDepartId?: number
  updateAdvertisingShowTime?: string
  updateUserId?: number
  updateUserName?: string
}
interface INav {
  name: string
  path: {
    link: [number, string] | string | number
    name: string
    type: string
    spuId?: string
    skuId?: string
    microPageId?: string
    inheritBanner?: number
  }
}

const useDecorateStore = defineStore({
  id: 'decorate',
  persist: {
    // 修改存储中使用的键名称，默认为当前 store的 id
    key: 'decorate',
    // 修改为 sessionstorage，默认为 localstorage
    storage: sessionStorage
  },
  state: () => {
    return {
      decorateAllInfo: {}, // 当前装修的总数据
      pageContentCfgList: [], // 后台页面装修样式的配置
      pageRenderCfgList: [], // 页面渲染样式的配置

      headerCfg: {}, // 头部的装修信息
      headerContentCfgList: [], // 头部的数组形式数据

      showSearch: true, // 是否展示搜索栏
      showNavs: true, // 是否展示导航
      showCategory: true, // 是否展示全部拼配
      navList: [], // 导航栏所有
      loading: false,

      inheritDecorateId: -1
    } as {
      decorateAllInfo: IDecorateConfig
      pageContentCfgList?: IExtendObj[] | []
      pageRenderCfgList?: IExtendObj[] | []

      headerCfg: IExtendObj
      headerContentCfgList: IExtendObj[] | []

      pageBackGroundCfg: IExtendObj[] | []
      showSearch?: boolean
      showNavs?: boolean
      showCategory?: boolean
      navList?: INav[] | []
      loading: boolean
      inheritDecorateId: number
    }
  },
  actions: {
    /**
     * 只获取首页的头部信息
     */
    async getHomePageHeaderDecorate() {
      try {
        const shopStore = useShopStore()
        const shopId = shopStore.store?.currentStore?.cusStoreId
        this.resetDecorateInfo()
        // 获取装修信息
        this.loading = true
        const { content } = await queryHomePageRenovationInfo({
          // 装修类型 renovationType 1 pc 2 移动
          jsonData: { shopId, renovationType: 1 }
        })
        if (content) {
          await this.setDecorateInfo(content, false, false)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    /**
     * 获取首页信息
     */
    async getHomePageDecorate() {
      try {
        const shopStore = useShopStore()
        const shopId = shopStore.store?.currentStore?.cusStoreId
        this.resetDecorateInfo()
        // 获取装修信息
        this.loading = true
        const { content } = await queryHomePageRenovationInfo({
          // 装修类型 renovationType 1 pc 2 移动
          jsonData: { shopId, renovationType: 1 }
        })
        if (content) {
          await this.setDecorateInfo(content, true, false)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    /**
     * 获取装修详情页面信息
     */
    async getSelectDecorate(renovationId: string | number) {
      try {
        // 获取装修信息
        this.loading = true
        const { content } = await queryPageRenovationInfo({
          jsonData: { id: renovationId }
        })
        if (content) {
          await this.setDecorateInfo(content, true, false)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    setInheritDecorateId() {
      this.inheritDecorateId = this.decorateAllInfo.id ?? -1
    },
    async setInheritBanner() {
      if (this.inheritDecorateId === -1) return
      try {
        // 获取装修信息
        this.loading = true
        const { content } = await queryPageRenovationInfo({
          jsonData: { id: this.inheritDecorateId }
        })
        if (content) {
          await this.setDecorateInfo(content, false, true)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    /**
     * 还原装修信息
     */
    resetDecorateInfo() {
      this.decorateAllInfo = {} // 当前装修的总数据

      this.pageContentCfgList = [] // 后台页面装修样式的配置
      this.pageRenderCfgList = [] // 页面渲染样式的配置

      this.headerCfg = {} // 头部的装修信息
      this.headerContentCfgList = [] // 头部的数组形式数据

      this.showSearch = true // 是否展示搜索栏
      this.showNavs = true // 是否展示导航
      this.showCategory = true // 是否展示全部拼配
      this.navList = [] // 导航栏所有
    },
    /**
     * 根据接口返回信息 进行数据二次装修
     * @param res 接口返回信息
     * @param matchproductsFlag 是否需要匹配商品信息
     */
    async setDecorateInfo(
      res: IExtendObj,
      matchproductsFlag: boolean = true,
      onlySetBanner: boolean = false
    ) {
      try {
        let pageContentCfgList: IExtendObj[] = []
        let headerContentCfgList: IExtendObj[] = []
        if (res && res.content) {
          const homePageContent = JSON.parse(res.content)
          if (Array.isArray(homePageContent)) {
            homePageContent.forEach((element) => {
              element.type != 'shop_signs'
                ? pageContentCfgList.push(element)
                : headerContentCfgList.push(element) // 头部的数组形式数据
            })
          }
        }
        // 整体配置数据
        this.decorateAllInfo = res
        // 装修主体的数据
        this.pageContentCfgList = pageContentCfgList
        if (matchproductsFlag) {
          // 页面渲染
          this.pageRenderCfgList = await updateConfigList(cloneDeep(this.pageContentCfgList))
        }

        // 头部配置数据
        const headerCfg = headerContentCfgList[0]?.dataField
        this.headerCfg = headerCfg
        this.headerContentCfgList = headerContentCfgList // 头部的数组形式数据

        // 是否展示头部
        this.showSearch = headerCfg
          ? headerCfg.showSearch !== undefined
            ? headerCfg.showSearch === 0
            : true
          : false
        // 是否展示导航
        this.showNavs = headerCfg
          ? headerCfg.showNavs !== undefined
            ? headerCfg.showNavs === 0
            : true
          : false
        // 是否展示拼配
        this.showCategory = headerCfg
          ? headerCfg.showCategory !== undefined
            ? headerCfg.showCategory === 0
            : true
          : false
        // 导航栏
        this.navList = headerCfg ? (headerCfg.navList !== undefined ? headerCfg.navList : []) : []

        console.log(this.pageContentCfgList, this.pageRenderCfgList, 'this.pageRenderCfgList')
      } catch (error) {
        console.error(error)
      }
    }
  }
})
// 匹配商品 跟 品牌
async function updateConfigList(configList: any[]) {
  try {
    if (Array.isArray(configList) && configList.length > 0) {
      const shopStore = useShopStore()
      const shopId = shopStore.store?.currentStore?.cusStoreId

      const decorateStore = useDecorateStore()
      const purchaseMode = decorateStore.decorateAllInfo.purchaseMode

      const {
        skuIdList = [],
        brandIdList = [],
        goodsGroupIdList = [],
        brandGroupIdList = []
      } = DecoratePC.getUpdateIdListFromConfigList(configList)
      let goodsList = []
      let brandList = []
      let goodsGroupList = []
      let brandGroupList = []

      // 根据现有的id查询所有的商品信息
      if (skuIdList && skuIdList.length) {
        let {
          content: { records }
        } = await selectGoodsBySkuList({
          jsonData: {
            imageWide: 400,
            imageHigh: 400,
            skuIdList,
            psStoreId: shopId,
            supplyPriceType: purchaseMode,
            pageSize: 10000
          }
        })
        goodsList = records.map(DecoratePC.productCardInfoTransform)
      }

      if (brandIdList && brandIdList.length) {
        // 根据现有的id查询所有的商品信息
        const {
          content: { records }
        } = await queryBrandPageForPc({
          jsonData: {
            imageWide: 200,
            imageHigh: 200,
            idList: brandIdList,
            pageNum: 1,
            pageSize: 10000
          }
        })
        brandList = records
      }

      // 商品组数据
      if (goodsGroupIdList && goodsGroupIdList.length) {
        // 根据现有的id查询所有的商品组信息
        const {
          content: { data }
        } = await queryGoodsGroup({
          jsonData: { ids: goodsGroupIdList, pageNum: 1, pageSize: 10000 }
        })
        console.log(data, 'data')
        goodsGroupList = data
      }

      // 品牌组
      if (brandGroupIdList && brandGroupIdList.length) {
        // 根据现有的id查询所有的品牌组信息
        const {
          content: { data }
        } = await queryBrandGroupPage({
          jsonData: { ids: brandGroupIdList, pageNum: 1, pageSize: 10000 }
        })
        brandGroupList = data
      }

      return DecoratePC.updateConfigList(
        configList,
        goodsList,
        handleScreenGoods,
        brandList,
        handleScreenBrands,
        goodsGroupList,
        DecorateCommon.getGoodsGroupArrByBackReturnArr,
        brandGroupList,
        DecorateCommon.getBrandGroupArrByBackReturnArr
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function handleScreenGoods(currentArr: any, backReturnArr: any, type: any, otherParams: any) {
  try {
    let params = {}
    if (otherParams) {
      params = { ...otherParams }
    } else {
      params = DecorateCommon.goodsParamReplace()
    }
    return DecorateCommon.getGoodsArrByBackReturnArr(currentArr, backReturnArr, type, params)
  } catch (error) {
    console.error(error)
  }
}

function handleScreenBrands(currentArr: any, backReturnArr: any, type: any, otherParams: any) {
  let params = {}
  if (otherParams) {
    params = { ...otherParams }
  } else {
    params = DecorateCommon.brandParamReplace()
  }
  return DecorateCommon.getBrandArrByBackReturnArr(currentArr, backReturnArr, type, params)
}

export default useDecorateStore
