<template>
  <el-tag v-bind="$attrs" :class="props.disabled && 'tag-disable'">{{ value }}</el-tag>
</template>
<script setup lang="ts">
  import { ref, computed } from 'vue'
  interface IYfTagProps {
    modelValue: string
    disabled?: boolean
  }
  /**
   * 自定义事件
   */
  interface IYfTagEmits {
    (e: 'update:modelValue', value: string): void
  }
  const props = defineProps<IYfTagProps>()
  const emit = defineEmits<IYfTagEmits>()
  const value = computed({
    get: () => props.modelValue,
    set: (val) => {
      emit('update:modelValue', val)
    }
  })
</script>

<style scoped lang="scss">
  .tag-disable {
    padding: 0 10px;
    line-height: 24px;
    text-align: center;
    background: #dbdbdb;
    font-size: 14px;
    color: #ffffff;
    border: transparent;
  }
</style>
