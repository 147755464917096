<template>
  <div class="sign-contarct-dialog">
    <el-dialog
      v-model="props.visible"
      title="签署合同补充协议"
      width="800px"
      :close-on-click-modal="false"
      :beforeClose="beforeClose"
    >
      <div class="content">
        <svg-icon icon-class="sign" :size="70" />
        <div class="text"
          >您有<span style="color: #a21f2d; font-size: 20px">{{ notSignContractCount }}份</span
          >合同补充协议尚未签署，可能会影响您下单及部分功能的体验，请尽快签署！</div
        >
      </div>
      <div class="agree-list">
        <div
          v-for="item in notSignContractList"
          :key="item.cusCustomerSubjectId"
          class="agree-item"
        >
          <div class="content"
            ><div class="label"
              >签约主体：{{ item.cusCustomerSubjectName }}
              <span>{{
                item.organization || item.idCard ? `（${item.organization || item.idCard}）` : ''
              }}</span></div
            ></div
          >
          <div
            :class="{ 'is-on-line': Number(item.certificationMethod) === 2 }"
            class="value"
            @click="handleClick(item)"
          >
            <span v-if="Number(item.certificationMethod) === 1"
              >有{{ item.notSignContractCount }}份补充协议未签署，请联系业务员签署补充协议！</span
            >
            <span
              v-else
              v-auth="'account:supplementaryAgreement'"
              class="value is-on-line"
              @click="handleClick(item)"
            >
              有{{ item.notSignContractCount }}份补充协议未签署，去签署
              <svg-icon
                v-if="Number(item.certificationMethod) === 2"
                icon-class="arrow-top"
                style="color: #a21f2d; transform: rotate(90deg)"
                :size="16"
              />
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
  <el-dialog
    v-model="visibleScreen"
    custom-class="contract-dialog"
    title="合同预览"
    width="1200px"
    lock-scroll
    fullscreen
    :before-close="beforeScreenClose"
  >
    <div style="height: 700px">
      <iframe
        id="Iframe"
        :src="pdfWebPath"
        frameborder="0"
        scrolling="auto"
        class="iframe-pdf"
        width="100%"
        height="100%"
      />
    </div>
    <template #footer>
      <span class="contract-dialog-footer">
        <el-button :loading="loading" type="primary" @click="toConfirmSign">确认并签署</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
  import { ref, computed, watch } from 'vue'
  import router from '@/router/index'
  import { contractPreview, getSignUrl } from '@/api/cus'
  import useCustomerStore from '@/store/customer'
  import useUserStore from '@/store/user'
  import { IExtendObj } from '@/types'
  import { ShowSelectAuthenticationDialog } from '@/components/SelectAuthenticationDialog/selectAuthenticationDialog'

  interface IProps {
    visible: boolean
  }

  interface IEmits {
    (e: 'resolve', params: IExtendObj): void

    (e: 'reject', params: IExtendObj): void

    (e: 'success'): void
  }

  const emit = defineEmits<IEmits>()
  const props = defineProps<IProps>()

  // const router = useRouter()
  const userStore = useUserStore()
  const useCustomer = useCustomerStore()

  watch(
    () => userStore.user,
    (val) => {
      if (!val) {
        resolve({ to: 'close' })
      }
    },
    { deep: true, immediate: true }
  )

  // 补充协议数量
  const notSignContractCount = computed(() => useCustomer.customer?.notSignContractCount)
  const notSignContractList = computed(() => useCustomer.customer?.notSignContractList)

  const visibleScreen = ref<boolean>(false)
  const beforeScreenClose = () => (visibleScreen.value = false)
  const loading = ref<boolean>(false)
  const pdfWebPath = ref<string>('')
  const resolve = (data: IExtendObj) => {
    emit('resolve', data)
    console.log(111)
  }

  const reject = (data: IExtendObj) => {
    emit('reject', data)
  }

  const beforeClose = () => {
    emit('success')
    resolve({ to: 'close' })
  }

  const cusCertificationInfoId = ref<number>() // 实名主体
  const notContactList = ref<IExtendObj>([]) // 未签署补充协议
  // 去签署
  const handleClick = (item: IExtendObj) => {
    if (Number(item.certificationMethod) === 1) return
    // emit('success')
    console.log('跳转合同预览')
    cusCertificationInfoId.value = item.cusCustomerSubjectId
    reviewContract(item)
    // resolve({ to: 'toConfirm' })
  }
  // 预览合同
  async function reviewContract(item: IExtendObj) {
    try {
      notContactList.value = item.notSignContractList
      const { content } = await contractPreview({
        jsonData: {
          customerSubjectId: cusCertificationInfoId.value,
          supplementTemplateCode: notContactList.value[0].contractTemplateCode
        }
      })
      pdfWebPath.value = content.previewUrl
      visibleScreen.value = true
    } catch (e) {
      console.log(e)
    }
  }
  // 签署合同协议
  async function toConfirmSign() {
    try {
      loading.value = true
      const { content } = await getSignUrl({
        jsonData: {
          customerSubjectId: cusCertificationInfoId.value,
          supplementTemplateCode: notContactList.value[0].contractTemplateCode
        }
      })
      window.open(content.signUrl, '_blank')
      loading.value = false
      beforeClose()
      emit('success')
      resolve({ to: 'toConfirm' })
    } catch (e) {
      loading.value = false
      visibleScreen.value = false
    }
  }
</script>
<style lang="scss" scoped>
  .sign-contarct-dialog {
    .content {
      display: flex;

      .text {
        font-size: 16px;
        margin-left: 10px;
        line-height: 24px;
      }
    }

    :deep(.el-dialog) {
      border-radius: 16px;
    }

    :deep(.el-dialog__footer) {
      text-align: center;
    }

    .desc-btn {
      color: rgb(72, 67, 67);
      display: inline-block;
      margin-left: 8px;
      cursor: pointer;
    }
    .agree-list {
      margin-top: 30px;
      .agree-item {
        display: flex;
        align-items: center;
        //justify-content: space-between;
        margin-bottom: 4px;
        .content {
          color: #666;
        }
        .value {
          color: #a21f2d;
          margin-left: 20px;
        }
        .is-on-line {
          display: flex;
          align-items: center;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
</style>
