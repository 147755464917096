<template>
  <el-card class="base-card">
    <template #header>
      <div class="header-box">
        <span>{{ props.title }}</span>
      </div>
    </template>
    <template v-for="item in props.column">
      <div class="card-content" v-if="!item.isHide">
        <div class="row" :key="item.prop">
          <div :style="item.style ? item?.style : {}" style="display: flex">
            <span class="label">{{ item.label }}：</span>
            <yf-text
              v-if="item.type === 'text'"
              v-model="item.value"
              :isEye="item.isEye"
              :params="item.params"
              :style="item.style && item.style.textStyle ? item?.style.textStyle : {}"
              @handleChangeEye="handleChangeEye"
            />
            <span
              class="text"
              v-if="item.type === 'money'"
              :style="item.style && item.style.textStyle ? item?.style.textStyle : {}"
              >¥ {{ thousandFormatter(item.value) }}</span
            >
            <div v-if="item.type === 'image'" class="file-box">
              <YFImage class="images" v-model="item.value" isShowViewer></YFImage>
            </div>
            <component
              v-else
              :is="item.component"
              v-bind="item"
              v-model="item.value"
              v-on="item.event ?? {}"
            />
            <span v-if="item.isCopy && item.value" class="copy" @click="handleCopy(item.value)"
              >复制</span
            >
          </div>
        </div>
      </div>
    </template>
  </el-card>
</template>
<script setup lang="ts">
  import type { ICardColumn } from './types'
  import { thousandFormatter } from '@/utils/utils'
  interface IProps {
    title: string
    column: ICardColumn[]
  }

  interface IEmits {
    (e: 'handleCopy', params: string): void

    (e: 'handleChangeEye', params: any): void
  }

  const props = defineProps<IProps>()
  const emit = defineEmits<IEmits>()

  const handleCopy = (value: string) => {
    emit('handleCopy', value)
  }
  const handleChangeEye = (type: any) => {
    console.log(type)
    emit('handleChangeEye', type)
  }
</script>
<style lang="scss" scoped>
  .base-card {
    box-shadow: none;
    border-radius: 2px;
    border: 1px solid #eeeeee;
  }

  :deep(.el-card__header) {
    padding: 16px;
    font-weight: bold;
    border-bottom: none;
    text-align: left;

    .header-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 14px;
    }
  }

  :deep(.el-card__body) {
    padding: 0 16px 16px 0;

    .card-content {
      .row {
        padding: 3px;
        .el-checkbox {
          height: 14px !important;
          margin-top: 1px;
        }
        .el-checkbox__label {
          display: none;
        }
      }

      .label {
        font-weight: bold;
        width: 100px;
        text-align: right;
      }

      .copy {
        color: #007aff;
        padding: 0px 5px;
        cursor: pointer;
      }

      .file-box {
        flex: 1;

        .images {
          height: 90px;
          display: flex;
          padding: 5px;
          .el-image {
            width: 90px;
            height: 90px;
            margin-right: 8px;
          }
        }
      }
    }
  }
</style>
