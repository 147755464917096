<template>
  <div v-if="props.mode === 'expand'">
    <!-- 需要展开时 -->
    <div v-if="isShow || isExpand" :style="props.lineClamp === 1 ? { display: 'flex' } : {}">
      <div
        ref="boxRef"
        :class="!isExpand ? 'close' : ''"
        :style="{
          '-webkit-line-clamp': props.lineClamp,
          'line-clamp': props.lineClamp
        }"
      >
        <slot />
      </div>
      <label
        class="label-class"
        :style="props.lineClamp === 1 ? { 'padding-left': '10px', height: '20px' } : {}"
        @click="isExpand = !isExpand"
      >
        {{ isExpand ? props.closeText : props.expandText }}
        <el-icon v-if="props.lineClamp === 1" class="arrow-down" :class="{ top: isExpand }">
          <ArrowDown />
        </el-icon>
      </label>
    </div>

    <!-- 不需要展开时 -->
    <div v-if="!isShow && !isExpand">
      <div ref="boxRef">
        <slot />
      </div>
    </div>
  </div>
  <YfPopper
    v-else
    trigger="hover"
    :placement="props.placement"
    :width="props.width"
    :teleported="props.teleported"
    :disabled="!isShow"
    :className="props.className"
  >
    <template #content>
      <slot></slot>
    </template>
    <template #btn>
      <div
        class="text-overflow-wrap close"
        ref="boxRef"
        :style="{
          '-webkit-line-clamp': props.lineClamp,
          'line-clamp': props.lineClamp,
          ...props.style
        }"
      >
        <slot></slot>
      </div>
    </template>
  </YfPopper>
</template>
<script setup lang="ts">
  import { onMounted, onUpdated, ref, nextTick } from 'vue'
  import YfPopper from '@/components/YfPopper/index.vue'
  import { IExtendObj } from '@/types'

  type TProps = {
    lineClamp?: number
    lineHeight?: number
    expandText?: string
    closeText?: string
    teleported: boolean
    className: string
    mode?: 'expand' | 'hover'
    style?: IExtendObj
    placement?: string
    width?: string
  }
  const boxRef = ref<any>(null)
  let isShow = ref(false)
  const isExpand = ref(false)
  const props = withDefaults(defineProps<TProps>(), {
    lineClamp: 3,
    lineHeight: 23,
    expandText: '展开',
    closeText: '收起',
    mode: 'expand',
    teleported: false,
    className: '',
    placement: 'bottom-end',
    width: '280px'
  })
  // 组件初渲染时不会触发onUpdated，需要通过onMounted触发计算
  onMounted(() => {
    showMore()
  })
  onUpdated(() => {
    showMore()
  })

  function showMore() {
    if (boxRef.value) {
      nextTick(() => {
        isShow.value = boxRef.value.scrollHeight > props.lineClamp * props.lineHeight
        // console.log(
        //   boxRef,
        //   boxRef.value.scrollHeight,
        //   props.lineClamp,
        //   props.lineHeight,
        //   isShow.value
        // )
      })
    }
  }
</script>
<style lang="scss" scoped>
  .close {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .label-class {
    display: flex;
    align-items: center;
    color: #a21f2d;
    cursor: pointer;

    &:hover {
      color: #a21f2d;
    }

    .top {
      transform: rotate(180deg);
    }
  }
</style>
