<template>
  <div class="select-authentication-dialog">
    <el-dialog
      v-model="props.visible"
      title="请选择签约对象"
      width="700px"
      :close-on-click-modal="false"
      :beforeClose="beforeClose"
    >
      <el-radio-group v-model="infoForm.cusCertificationInfoId" @change="handleChange">
        <el-radio
          v-for="item in certificationList"
          :key="item.id"
          :label="item.id"
          :disabled="Number(item.signContractStatus) === 2"
        >
          {{ item.personName }} --
          {{ Number(item.signContractStatus) === 2 ? '已签署' : '未签署' }}合同
        </el-radio>
      </el-radio-group>
      <template #footer>
        <span class="contract-dialog-footer">
          <el-button type="primary" @click="toConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
  import { computed, reactive, ref, watch } from 'vue'
  import { IExtendObj } from '@/types'
  import { getCertificationInfo, getSignUrl } from '@/api/cus'
  import useUserStore from '@/store/user'
  import router from '@/router/index'
  import { ElMessage } from 'element-plus'

  interface IProps {
    visible: boolean
    type: number
  }

  interface IEmits {
    (e: 'resolve', params: IExtendObj): void

    (e: 'reject', params: IExtendObj): void

    (e: 'success'): void
  }

  const userStore = useUserStore()
  const props = defineProps<IProps>()
  const emit = defineEmits<IEmits>()
  const baseFormRef = ref<any>()

  const certificationList = ref<IExtendObj[]>([])
  const certificationInfo = async () => {
    const params = {
      cusCustomerId: userStore.user?.customerId
    }
    try {
      const { content } = await getCertificationInfo({
        jsonData: params
      })
      certificationList.value = content.map((item: IExtendObj) => {
        return {
          personName: `${item.personName}（${
            Number(item.subjectType) === 2 ? '企业已认证' : '个人已认证'
          }）`,
          id: item.id,
          signContractStatus: item.signContractStatus,
          certificationMethod: item.certificationMethod
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  const infoForm = reactive({
    cusCertificationInfoId: '',
    signContractStatus: '',
    certificationMethod: ''
  })

  watch(
    () => props.visible,
    (val) => {
      if (val) {
        certificationInfo()
      }
    }
  )
  const resolve = (data: IExtendObj) => {
    emit('resolve', data)
  }

  const reject = (data: IExtendObj) => {
    emit('reject', data)
  }

  const beforeClose = () => {
    emit('success')
    resolve({})
  }
  const handleChange = (val: any[]) => {
    console.log(val)
    const certificationAry = certificationList.value.filter(
      (item) => Number(item.id) === Number(val)
    )
    infoForm.signContractStatus = (certificationAry || [])[0].signContractStatus
    infoForm.certificationMethod = (certificationAry || [])[0].certificationMethod
  }
  const toConfirm = async () => {
    if (!infoForm.cusCertificationInfoId) {
      return ElMessage.warning('请选择签约对象')
    }
    if (Number(infoForm.certificationMethod) !== 2) {
      return ElMessage.warning('该认证方式是线下认证方式，请联系业务员线下签署合同！')
    }
    if (Number(infoForm.signContractStatus) === 3) {
      try {
        const { content } = await getSignUrl({
          jsonData: {
            cusCertificationInfoId: infoForm.cusCertificationInfoId
          }
        })
        window.open(content.signUrl, '_blank')
      } catch (e) {}
    } else {
      console.log(router)
      router.push({
        path: '/contract',
        query: {
          cusCertificationInfoId: infoForm.cusCertificationInfoId
        }
      })
    }
    resolve({})
  }
</script>

<style scoped lang="scss">
  .select-authentication-dialog {
    ::v-deep {
      .el-dialog__body {
        padding: 8px 20px;
      }
      .el-radio-group {
        align-items: flex-start;
        flex-wrap: wrap;
      }
      .el-radio__label {
        white-space: normal;
      }
    }
  }
</style>
