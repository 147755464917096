import { ShowStoreDialog } from '@/components/StoreDialog/messageModal'
import { changeUserStore, IStore } from '@/api/sc'
import useShopStore from '@/store/store'
import useUserStore from '@/store/user'

export async function storeValidate(isOpen?: boolean) {
  const useShop = useShopStore()
  const userStore = useUserStore()
  try {
    console.log(isOpen, useShop.store?.userStoreList)
    if (!isOpen) return
    // 判断客户是否授权两个店铺，如果授权两个账号则弹框选择店铺后再进入首页
    if (useShop.store?.userStoreList && useShop.store?.userStoreList.length > 1) {
      let userStoreList
      if (useShop.store?.currentStore) {
        userStoreList = useShop.store?.userStoreList.map((item: IStore) => {
          item.disabled = item.cusStoreId === useShop.store?.currentStore?.cusStoreId
          return item
        })
      } else {
        userStoreList = useShop.store?.userStoreList
      }
      const res = await ShowStoreDialog({ userStoreList: userStoreList })
      if (res && res.currentStore) {
        await changeUserStore({ cusStoreId: res.currentStore.cusStoreId })
        // 选择完店铺 更新店铺信息
        useShop.updateShop({
          ...useShop.store,
          currentStore: {
            ...res.currentStore
          }
        })
      } else {
        // 没有选择店铺 关闭弹框后 清除登录信息、店铺信息，默认退出登录
        useShop.removeShop()
        userStore.removeUser()
        return
      }
    }
  } catch (e) {
    userStore.removeUser()
  }
}
