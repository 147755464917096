<template>
  <yf-dialog
    :visible="props.visible"
    title="实名认证"
    width="400px"
    :close-on-click-modal="true"
    :button-list="buttonList"
    :beforeClose="beforeClose"
    @footerButtonClick="beforeClose"
  >
    <div v-loading="loading" class="code-content"
      ><img :src="code" style="width: 300px; height: 300px" alt="小程序码" />
      <p>提示：个人用户请到微信/支付宝小程序进行实名认证</p>
    </div>
  </yf-dialog>
</template>
<script lang="ts" setup>
  import { ref, watch } from 'vue'
  import { getCertificationCode } from '@/api/cus'
  import type { IExtendObj } from '@/types'

  interface IProps {
    visible: boolean
    type: number
  }

  interface IEmits {
    (e: 'resolve', params: IExtendObj): void

    (e: 'reject', params: IExtendObj): void

    (e: 'success'): void
  }

  const emit = defineEmits<IEmits>()
  const props = defineProps<IProps>()

  const visible = ref<boolean>(false)
  const buttonList = [
    {
      event: 'ok',
      label: '确定',
      type: 'primary'
    }
  ]
  const loading = ref<boolean>(false)
  const open = async () => {
    const params = {
      type: props.type,
      miniAppType: 2
    }
    try {
      loading.value = true
      const { content } = await getCertificationCode(params)
      loading.value = false
      code.value = content
    } catch (e) {
      loading.value = false
    }
  }
  const code = ref<string>('')
  const resolve = (data: IExtendObj) => {
    emit('resolve', data)
  }

  const reject = (data: IExtendObj) => {
    emit('reject', data)
  }
  const beforeClose = () => {
    emit('success')
    resolve({ to: 'close' })
  }
  watch(
    () => props.visible,
    (val) => {
      console.log(111, val)
      if (val) {
        open()
      }
    }
  )
</script>
<style lang="scss" scoped>
  .code-content {
    text-align: center;

    p {
      margin-top: 10px;
      color: #a21f2d;
    }
  }
</style>
