<template>
  <svg aria-hidden="true" class="svg-icon" :width="props.size" :height="props.size">
    <use :xlink:href="symbolId" rel="external nofollow" :fill="props.color" />
  </svg>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  const props = defineProps({
    prefix: {
      type: String,
      default: 'icon'
    },
    iconClass: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#333'
    },
    size: {
      type: String,
      default: '1em'
    }
  })
  const symbolId = computed(() => `#${props.prefix}-${props.iconClass}`)
</script>
<style scoped>
  .svg-icon {
    /* width: 1em;
    height: 1em; */
    vertical-align: -0.15em;
    overflow: hidden;
    fill: currentColor;
  }
</style>
