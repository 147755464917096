<template>
  <el-select-v2
    class="yf-select"
    v-bind="$attrs"
    :model-value="value"
    :options="options"
    :popper-class="popperClass"
    :height="props.height"
    filterable
    :clearable="clearable"
    :remote="isTable"
    :remote-method="handleRemoteMethod"
    @change="handleChange"
    @visible-change="handleVisibleChange"
  >
    <template #default="{ item }">
      <template v-if="!isTable"> {{ item.label }} </template>
      <template v-else>
        <template v-if="item.label === '###' && item.value === '###'">
          <span
            class="yf-select__col yf-select__col--header"
            v-for="col in column"
            :key="col.prop"
            :disabled="col.disabled"
            :style="{ width: `${col.width}px` }"
          >
            {{ col.label }}
          </span>
        </template>
        <template v-else>
          <span
            class="yf-select__col"
            v-for="col in column"
            :key="col.prop"
            :disabled="col.disabled"
            :style="{ width: `${col.width}px` }"
            :title="item[col.prop]"
          >
            {{ item[col.prop] }}
          </span>
        </template>
      </template>
    </template>
  </el-select-v2>
</template>
<script setup lang="ts">
  import { IExtendObj } from '@/types'
  import { ElSelectV2 } from 'element-plus'
  import { computed, ref, nextTick } from 'vue'
  import type { IYfSelectColumn } from './types'
  /**
   * 表格配置属性
   */
  interface IYfSelectProps {
    modelValue: string | number | string[] | number[]
    options: IExtendObj[]
    optionName?: { label: string; value: string }
    column?: IYfSelectColumn[]
    height?: number
    labelName?: string
    isSelectData?: IExtendObj[]
    propKey: string
    clearable?: boolean
  }
  /**
   * 自定义事件
   */
  interface IYfSelectEmits {
    (e: 'change', val: number | string | Array<number | string>, item: any): void
    (e: 'update:modelValue', value: {}): void
  }
  const props = withDefaults(defineProps<IYfSelectProps>(), {
    options: [] as any,
    column: [] as any,
    height: 200
  })
  const emit = defineEmits<IYfSelectEmits>()

  const value = computed({
    get: () => {
      console.log(props)
      return props.labelName ? props.labelName : props.modelValue
    },
    set: (value) => {
      emit('update:modelValue', value)
    }
  })

  const isTable = computed(() => !!props.column.length)
  const column = computed(() => props.column)
  const popperWidth = computed(() =>
    column.value.reduce((width, item) => {
      width += item.width
      return width
    }, 52)
  )
  const keyWord = ref('')
  const options = computed(() => {
    console.log(props.isSelectData, props.propKey)
    const ids = (props.isSelectData || []).map((item: IExtendObj) => item[props.propKey])
    const list = props.options
      .filter((o) => !ids.includes(o[props.propKey]))
      .map((item) => ({
        ...item,
        label: item[props.optionName?.label || 'label'],
        value: item[props.optionName?.value || 'value']
      }))
    console.log(list, keyWord.value)
    if (isTable.value) {
      const newList = list.filter((item: any) =>
        column.value.reduce((flag, col) => {
          return flag || (item[col.prop] || '').includes(keyWord.value)
        }, false)
      )
      return [
        {
          label: '###',
          value: '###',
          disabled: true
        },
        ...newList
      ]
    } else {
      return list
    }
  })
  const popperClass = computed(() => (isTable.value ? 'yf-select-popper--table' : ''))
  /**
   * 在获取焦点的时候对所有下拉框的表格进行宽度调整
   */
  function handleVisibleChange(visible: boolean) {
    if (!visible) {
      keyWord.value = ''
      return
    }
    if (!isTable.value) {
      return
    }
    const eles = document.querySelectorAll(
      '.yf-select-popper--table .el-vl__window.el-select-dropdown__list'
    )
    eles.forEach((item: any) => {
      const style = item.style
      style.setProperty('--self-width', `${popperWidth.value}px`)
    })
  }
  function handleChange(val: number | string | Array<number | string>) {
    if (Array.isArray(val)) {
      const items = val.map((v) => options.value.find((item) => item.value === v))
      emit('change', val, items)
    } else {
      const item = options.value.find((item) => item.value === val)
      emit('change', val, item)
    }
    emit('update:modelValue', val)
  }
  function handleRemoteMethod(text: string) {
    keyWord.value = text
    nextTick(() => {
      handleVisibleChange(true)
    })
  }
  function handleFilterMethod(text) {}
</script>
<style scoped lang="scss">
  .yf-select {
    width: 100%;
    font-size: 12px;
    &__col {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      &--header {
        font-weight: bold;
        cursor: default;
        color: #333;
      }
    }
  }
</style>
<style lang="scss">
  .yf-select-popper--table .el-vl__window.el-select-dropdown__list {
    width: var(--self-width) !important;
  }
  .yf-select .el-select-v2__placeholder {
    text-align: left;
  }
</style>
