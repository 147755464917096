interface IExtendObj {
  /**
   * 扩展属性
   */
  [key: string]: any
}

// 字典值属性
interface IOptions {
  label: string
  value: string
  name?: string
}

// 状态流
interface IStateFlowItem {
  title: string
  description: string
}

export enum EOrderStatus {
  全部 = -1,
  待提交 = 1,
  待支付 = 3,
  待发货 = 4,
  部分发货 = 5,
  待收货 = 6,
  已完成 = 7,
  已取消 = 8
}

export enum EWholesaleOrderStatus {
  全部 = -1,
  待提交 = 1,
  待确认 = 2,
  待支付 = 3,
  待发货 = 4,
  部分发货 = 5,
  待收货 = 6,
  已完成 = 7,
  已取消 = 8
}

export enum EOrderPayStatus {
  待支付 = 1,
  待支付财审 = 2,
  财审驳回 = 3,
  已支付 = 4,
  已取消 = 8,
  已失效 = 99,
  月结已财审 = 5,
  待提审 = 6
}

interface IMenu {
  title: string
  icon?: string
  path?: string
  parent?: IMenu
  name?: string
  type: 'group' | 'menu' | 'sub'
  menuIndex?: string
  children?: Array<IMenu>
}

export enum EPayType {
  线上支付 = 1,
  线下支付 = 2
}

export enum EOfflineType {
  微信 = 1,
  支付宝 = 2,
  银行卡 = 3,
  云闪付 = 5,
  数字人民币 = 6,
  企业网银 = 32, //前端使用
  个人网银 = 31 //前端使用
}

export enum EOrderType {
  批发 = '1',
  代发 = '2'
}

export enum EStoreType {
  自营店铺 = '2',
  内购店铺 = '1'
}
export enum EPackageStatus {
  '已揽件' = 1,
  '运输中',
  '派送中',
  '待取件',
  '已签收',
  '拒收',
  '问题件'
}
export type { IExtendObj, IMenu, IOptions, IStateFlowItem, EPackageStatus }
