import WithAutoLoadHoc from '@/components/WithAutoLoadHoc/index'
import YfSelect from '@/components/YfSelect/index.vue'
import YfButton from '@/components/YfButton/index.vue'
import YfText from '@/components/YfText/index.vue'
import YfQueryDatePicker from '@/components/YfQuery/components/YfQueryDatePicker/index.vue'
import YfImage from '@/components/YFImage/YFImage.vue'
import YfTag from '@/components/YfTag/index.vue'
import YfPhoneCode from '@/components/YfPhoneCode/index.vue'
import YfRadio from '@/components/YfRadio/index.vue'
import YfCheckbox from '@/components/YfCheckbox/index.vue'
import YfAddress from '@/components/YfAddress/index.vue'
import YfEye from '@/components/YfEye/index.vue'
import YfCard from '@/components/YFCard/index.vue'
import YfTags from '@/components/YfTags/index.vue'
import YfDateRange from '@/components/YfDateRange/index.vue'
import YfStatusLogo from '@/components/YfStatusLogo/index.vue'
import YfAddressInfo from '@/components/YfAddressInfo/index.vue'
import { App } from 'vue'

export default {
  install(app: App<Element>) {
    app.component('YfQueryDatePicker', YfQueryDatePicker)
    app.component('YfSelect', WithAutoLoadHoc(YfSelect))
    app.component('YfButton', YfButton)
    app.component('YfText', YfText)
    app.component('YfImage', YfImage)
    app.component('YfTag', YfTag)
    app.component('YfPhoneCode', YfPhoneCode)
    app.component('YfRadio', YfRadio)
    app.component('YfCheckbox', YfCheckbox)
    app.component('YfAddress', YfAddress)
    app.component('YfEye', YfEye)
    app.component('YfCard', YfCard)
    app.component('YfTags', YfTags)
    app.component('YfDateRange', YfDateRange)
    app.component('YfStatusLogo', YfStatusLogo)
    app.component('YfAddressInfo', YfAddressInfo)
  }
}
