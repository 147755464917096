<template>
  <div v-bind="$attrs" class="code-container">
    <el-input v-model="value" class="code" placeholder="请输入验证码" size="large"></el-input>
    <el-button
      class="send-code"
      type="primary"
      size="small"
      @click="handleSendSms"
      :disabled="countDown.ss !== '00'"
      >发送验证码{{ countDown.ss !== '00' ? `(${countDown.ss})` : '' }}</el-button
    >
  </div>
</template>
<script setup lang="ts">
  import useCountDown from '@/hooks/useCountDown'
  import { ref, computed } from 'vue'
  import { regSendSms } from '@/api/sc'
  import { ElMessage } from 'element-plus'

  interface IYfCodeProps {
    modelValue: string
    phone: string
    errorTips?: string
  }
  /**
   * 自定义事件
   */
  interface IYfCodeEmits {
    (e: 'update:modelValue', value: string): void
  }
  const props = defineProps<IYfCodeProps>()
  const emit = defineEmits<IYfCodeEmits>()
  const value = computed({
    get: () => props.modelValue,
    set: (val) => {
      emit('update:modelValue', val)
    }
  })
  let countDownMs = { leftTime: ref(0) }
  let { countDown } = useCountDown(countDownMs, () => {
    countDownMs.leftTime.value = 0
  })
  async function handleSendSms() {
    try {
      if (!props.phone) {
        ElMessage.error('请先输入手机号')
        return
      }
      if (props.errorTips) {
        ElMessage.error(props.errorTips)
        return
      }
      const mobileReg =
        /^0{0,1}(11[0-9]|12[0-9]|13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
      if (!mobileReg.test(props.phone.trim())) {
        // ElMessage.error('请输入正确的手机号')
        return
      }
      await regSendSms(props.phone)
      countDownMs.leftTime.value = 60000
    } catch (error) {}
  }
</script>
<style lang="scss" scoped>
  .code-container {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .code {
      ::v-deep(.el-input__wrapper) {
        box-shadow: none;
      }
    }
    .send-code {
      margin-right: 20px;
    }
  }
</style>
