import { computed, ref, watch } from 'vue'

interface ICountDown {
  dd: string
  hh: string
  mm: string
  ss: string
}

interface IParam {
  startTime?: string
  endTime?: string
  leftTime?: number
}

export default function useCountDown(param: IParam | any, callback?: any) {
  let leftTime = ref(0)
  let comeStartTime = 0
  let comeLeftTime = 0
  let timer: any = null

  watch(
    () => {
      return param
    },
    (newParam: IParam | any) => {
      // console.log(newParam)
      if (!newParam) {
        leftTime.value = 0
        return
      }
      let leftTimeVal = newParam.leftTime ? newParam.leftTime.value : 0
      if (leftTimeVal) {
        leftTime.value = leftTimeVal
      }
      // 记录初始时间，用于计算偏差
      comeStartTime = new Date().getTime()
      comeLeftTime = leftTime.value
      getTime()
    },
    {
      deep: true,
      immediate: true
    }
  )

  const countDown = computed(() => {
    if (leftTime.value <= 0) {
      leftTime.value = 0
    }
    return getCountDown(leftTime.value)
  })

  function getTime() {
    // 避免重复执行setTimeout
    timer && window.clearTimeout(timer)
    timer = setTimeout(() => {
      const systemRun = comeLeftTime - leftTime.value
      const actulRun = new Date().getTime() - comeStartTime - 1500
      const offset = actulRun - systemRun
      // console.log('系合计算的当前走过的时间：', systemRun)
      // console.log('实际走过的时间：', actulRun)
      // console.log('偏差：(ms)>>>>>>>', offset)

      if (leftTime.value > 0) {
        leftTime.value = leftTime.value - 1000 - offset
        // console.log('leftTime.value', leftTime.value)
        getTime()
      } else {
        // console.log('time out')
        callback && callback('timeout')
      }
    }, 1000)
  }

  // getTime()
  const clearTimeout = () => {
    timer && window.clearInterval(timer)
  }
  return {
    countDown,
    getCountDown,
    clearTimeout
  }
}

function getCountDown(time: number) {
  let dd = 0
  let hh = 0
  let mm = 0
  let ss = 0
  if (!time) return { dd: '00', hh: '00', mm: '00', ss: '00' }
  dd = Math.floor(time / 1000 / 60 / 60 / 24)
  hh = Math.floor((time / 1000 / 60 / 60) % 24)
  mm = Math.floor((time / 1000 / 60) % 60)
  ss = Math.floor((time / 1000) % 60)
  return {
    dd: padStart0(dd),
    hh: padStart0(hh),
    mm: padStart0(mm),
    ss: padStart0(ss)
  }
}

function padStart0(value: number | string): string {
  if (!value) return '00'
  value = value.toString()
  return value.padStart(2, '0')
}
