<template>
  <div class="sign-contarct-dialog">
    <el-dialog
      v-model="props.visible"
      title="签署合同"
      width="500px"
      :close-on-click-modal="false"
      :beforeClose="beforeClose"
    >
      <div class="content">
        <svg-icon icon-class="sign" :size="50" />
        <div class="text">您尚未签署合同，可能会影响您下单及部分功能的体验</div>
      </div>
      <template #footer>
        <span class="contract-dialog-footer">
          <el-button type="primary" @click="toConfirm">立即签署</el-button>
          <span class="desc-btn" @click="toGoods">跳过，先逛逛</span>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts" setup>
  import { ref, computed, watch } from 'vue'
  import router from '@/router/index'
  import { getCertificationInfo, getSignUrl } from '@/api/cus'
  import useCustomerStore from '@/store/customer'
  import useUserStore from '@/store/user'
  import { IExtendObj } from '@/types'
  import { ShowSelectAuthenticationDialog } from '@/components/SelectAuthenticationDialog/selectAuthenticationDialog'

  interface IProps {
    visible: boolean
  }

  interface IEmits {
    (e: 'resolve', params: IExtendObj): void

    (e: 'reject', params: IExtendObj): void

    (e: 'success'): void
  }

  const emit = defineEmits<IEmits>()
  const props = defineProps<IProps>()

  // const router = useRouter()
  const userStore = useUserStore()
  const useCustomer = useCustomerStore()
  const isFlag = computed(
    () =>
      Number(useCustomer.customer?.authenticationMethod) === 2 &&
      useCustomer.customer?.purchaseModeList &&
      useCustomer.customer?.purchaseModeList.length === 1
  )

  const resolve = (data: IExtendObj) => {
    emit('resolve', data)
    console.log(111)
  }

  const reject = (data: IExtendObj) => {
    emit('reject', data)
  }
  watch(
    () => props.visible,
    (val) => {
      if (val) {
        certificationInfo()
      }
    }
  )

  watch(
    () => userStore.user,
    (val) => {
      if (!val) {
        resolve({ to: 'close' })
      }
    },
    { deep: true, immediate: true }
  )

  const beforeClose = () => {
    emit('success')
    resolve({ to: 'close' })
  }
  const certificationList = ref<IExtendObj[]>([])
  const certificationInfo = async () => {
    const params = {
      cusCustomerId: userStore.user?.customerId,
      certificationMethod: 2
    }
    try {
      const { content } = await getCertificationInfo({
        jsonData: params
      })
      certificationList.value = content
    } catch (e) {
      console.log(e)
    }
  }
  const toConfirm = async () => {
    await ShowSelectAuthenticationDialog()
    emit('success')
    resolve({ to: 'toConfirm' })
  }
  const toGoods = () => {
    emit('success')
    /** 当前在商品详情页，不跳转 */
    if (router.currentRoute.value.name !== 'GoodDetail') {
      router.push({ path: '/homePage' })
    }
    resolve({ to: 'toGoods' })
  }
</script>
<style lang="scss" scoped>
  .sign-contarct-dialog {
    .content {
      display: flex;

      .text {
        font-size: 16px;
        margin-left: 10px;
        line-height: 24px;
      }
    }

    :deep(.el-dialog) {
      border-radius: 16px;
    }

    :deep(.el-dialog__footer) {
      text-align: center;
    }

    .desc-btn {
      color: rgb(72, 67, 67);
      display: inline-block;
      margin-left: 8px;
      cursor: pointer;
    }
  }
</style>
