import { defineStore } from 'pinia'
import { IStore, getUserStore, changeUserStore } from '@/api/sc'
import useUserStore from '@/store/user'
import { ElMessage } from 'element-plus'
import useCustomerStore from '@/store/customer'

/**
 * data:
 * currentStore: 当前进入店铺
 * userStoreList: 授权店铺列表
 */
const useShopStore = defineStore({
  id: 'store',
  state: () => {
    return {
      store: {},
      isSuccess: true
    } as { store?: { userStoreList: IStore[]; currentStore?: IStore }; isSuccess: boolean }
  },
  persist: true,
  actions: {
    async getShop(isLogin = true) {
      const userStore = useUserStore()
      const customerStore = useCustomerStore()
      try {
        const res = await getUserStore()
        console.log(res)
        const { content } = res
        if (!content.length) {
          ElMessage.warning('当前没有已授权的店铺，请联系管理员！')
          customerStore.removeCustomerInfo()
          userStore.removeUser()
          this.isSuccess = false
          return
        }
        if (content && content.length) {
          let currentStore
          if (content.length === 1) {
            currentStore = content[0]
            isLogin && (await changeUserStore({ cusStoreId: currentStore.cusStoreId }))
          }
          this.isSuccess = true
          this.store = { userStoreList: content, currentStore }
        }
      } catch (e) {
        userStore.removeUser()
        this.isSuccess = false
      }
    },
    removeShop() {
      this.store = undefined
    },
    updateShop(store: { userStoreList: IStore[]; currentStore?: IStore }) {
      this.store = store
      this.isSuccess = true
    }
  },
  getters: {
    shelfConfig(state) {
      return state.store?.currentStore.shelfConfig
    },
    storeType(state) {
      return state.store?.currentStore.storeType
    }
  }
})
export default useShopStore
