<template>
  <div class="yf-address-class">
    <el-cascader
      ref="yfAddress"
      v-model="value"
      :options="options"
      :props="props.props"
      :filterable="filterable"
      :disabled="props.disabled"
      v-bind="$attrs"
      placeholder="省/市/区"
      teleported
      class="yf-address"
      @change="handleChange"
    />
  </div>
</template>
<script lang="ts" setup>
  import { ref, computed, onBeforeMount } from 'vue'
  import type { IExtendObj } from '@/types'
  import useAddressStore from '@/store/address'

  const addressStore = useAddressStore()

  interface AddressType {
    modelValue: IExtendObj
    props: IExtendObj
    filterable: boolean
    isFilter?: string
    disabled?: boolean
  }

  /**
   * 自定义事件
   */
  interface IYfAddressEmits {
    (e: 'update:modelValue', value: {}): void

    (e: 'change', value: {}): void
  }

  const props = withDefaults(defineProps<AddressType>(), {
    filterable: true
  })
  const emit = defineEmits<IYfAddressEmits>()
  const value = computed({
    get: () => {
      return props.modelValue.value
    },
    set: (val) => {
      let nodes: Array<string> = yfAddress.value
        .getCheckedNodes()
        .map((item: IExtendObj) => item.pathLabels)
      let names: Array<string> | string = ''
      if (props.props.emitPath) {
        // 返回数组
        if (!props.props.multiple) {
          // 非多选
          names = nodes[0]
        } else {
          // 多选
          names = nodes
        }
      } else {
        // 返回最后一项
        if (!props.props.multiple) {
          // 非多选
          names = nodes[0][nodes[0].length - 1]
        } else {
          // 多选
          names = nodes.map((item) => {
            return item[item.length - 1]
          })
        }
      }
      const obj = {
        value: val,
        names: names
      }
      emit('update:modelValue', obj)
    }
  })
  const yfAddress = ref()
  onBeforeMount(async () => {
    if (!addressStore.address?.length) {
      await addressStore.getAddress()
    }
  })
  const options = computed(() => {
    if (props.isFilter === 'area') {
      const getLevel2 = (tree: any[] | undefined) => {
        if (tree) {
          tree.map((node) => {
            if (node.level < 1) {
              getLevel2(node.children)
            } else if (node.level === 1 && node.children) {
              node.children = []
            }
          })
        }
        return tree
      }
      const data = JSON.parse(JSON.stringify(addressStore.address))
      return JSON.parse(JSON.stringify(getLevel2(data)))
    } else {
      console.log(222, addressStore.address)
      return addressStore.address
    }
  })
  const handleChange = (val: Array<string> | string) => {
    emit('change', { val })
  }
</script>
<style lang="scss" scoped>
  .yf-address-class {
    width: 100%;

    :deep(.yf-address) {
      width: 100%;
    }
  }
</style>
