import { defineStore } from 'pinia'
import { login, phoneLogin2, ILoginResult } from '@/api/sc'
import useCartStore from '@/store/cart'
type TLoginResultKeys = keyof ILoginResult
const useUserStore = defineStore({
  id: 'user',
  state: () => {
    return {
      user: {},
      isOpenedDefBindPhone: false,
      isOpenedEmptyPassUpdate: false
    } as { user?: ILoginResult; isOpenedDefBindPhone: boolean; isOpenedEmptyPassUpdate: boolean }
  },
  persist: true,
  actions: {
    async login(name: string, password: string) {
      const { content } = await login(name, password)
      this.user = { ...content }
    },
    async phoneLogin(phone: string, smsCode: string) {
      const { content } = await phoneLogin2(phone, smsCode)
      this.user = { ...content }
    },
    changeUpdatePass(flag = false) {
      if (this.user) {
        this.user.updatePass = flag
      }
    },
    removeUser() {
      const cartStore = useCartStore()
      cartStore.removeCart()
      this.user = undefined
      this.isOpenedDefBindPhone = false
      this.isOpenedEmptyPassUpdate = false
    },
    updateUser(users: ILoginResult) {
      this.user = users
    },
    updateUserByKey(key: TLoginResultKeys, value: any) {
      this.user[key] = value
    },
    setIsOpenedDefBindPhone(flag: boolean) {
      this.isOpenedDefBindPhone = flag
    },
    setIsOpenedEmptyPassUpdate(flag: boolean) {
      this.isOpenedEmptyPassUpdate = flag
    }
  }
})
export default useUserStore
