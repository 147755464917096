import useMenuStore from '@/store/menu'
function isAllowed(code: string) {
  const menuStore = useMenuStore()
  const buttonCodeList = menuStore.buttonCodeList

  if (buttonCodeList.length !== 0) {
    return menuStore.getIsButton(code)
  }
  return false
}
function isMenuAllowed(code: string) {
  const menuStore = useMenuStore()
  const menuCodeList = menuStore.menuCodeList

  if (menuCodeList.length !== 0) {
    return menuStore.getIsMenu(code)
  }
  return false
}
function auth(el: any, binding: any) {
  if ([null, undefined, ''].includes(binding.value)) {
    return
  }
  const allowed = binding.arg === 'menu' ? isMenuAllowed : isAllowed
  let isAllow = false
  if (Array.isArray(binding.value)) {
    isAllow = binding.value.reduce((flag: boolean, code: string) => {
      return flag && allowed(code)
    }, true)
  } else if (typeof binding.value === 'string' && binding.value.includes('|')) {
    isAllow = binding.value.split('|').reduce((flag: boolean, code: string) => {
      return flag || allowed(code)
    }, false)
  } else {
    isAllow = allowed(binding.value)
  }

  const element = el
  if (!isAllow && binding.value) {
    element.style.display = 'none'
    if (binding.arg && typeof binding.arg === 'function') {
      binding.arg({ tips: '没权限' })
    }
  } else {
    delete element.style.display
  }
}
import { App } from 'vue'
export default {
  install(app: App<Element>) {
    app.directive('auth', auth)
  }
}
