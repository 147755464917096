const login = () => import('@/views/login/index.vue')
const register = () => import('@/views/register/index.vue')
const test = () => import('@/views/test/index.vue')
const verifyResult = () => import('@/views/resultPageAndWx/verify-result.vue')
const contractResult = () => import('@/views/resultPageAndWx/contract-result.vue')
const guide = () => import('@/views/guide/index.vue')
const contract = () => import('@/views/contract/index.vue')
const error = () => import('@/views/error/404.vue')
const forgetPwd = () => import('@/views/forgetPwd/index.vue')
// const fast = () => import('@/views/stock/fast.vue')
const redirect = () => import('@/views/redirect/index.vue')
// const goodDetail = () => import('@/views/detail/index.vue')
const routes = [
  { path: '/login', name: 'Login', component: login },
  { path: '/register', name: 'Register', component: register },
  { path: '/test', name: 'Test', component: test },
  { path: '/verifyResult', name: 'VerifyResult', component: verifyResult },
  { path: '/contractResult', name: 'ContractResult', component: contractResult },
  { path: '/guide', name: 'Guide', component: guide },
  { path: '/contract', name: 'Contract', component: contract },
  { path: '/404', name: '404', component: error },
  { path: '/forgetPwd', name: 'ForgetPwd', component: forgetPwd },
  // { path: '/stock/fast', name: 'StockQuickOrder', component: fast },
  {
    path: '/homePage',
    name: 'HomePage',
    component: () => import('@/views/homePage/home.vue')
  },
  {
    path: '/select-decorate',
    name: 'SelectDecorate',
    component: () => import('@/views/homePage/select-decorate.vue')
  },
  { path: '/redirect/:path(.*)', name: 'Redirect', component: redirect }
  // {
  //   children: [
  //     {
  //       path: 'result',
  //       component: () => import('@/views/result/index.vue'),
  //       name: 'OrderWholesaleResult',
  //       meta: {
  //         title: '下单成功'
  //       }
  //     }
  //   ],
  //   component: () => import('@/components/layout/AppMain/index.vue'),
  //   path: '/wholesaleOrder'
  // }
  // { path: '/goodDetail', path: '/detail/index', component: goodDetail }
]
export default routes
