<template>
  <yf-eye v-bind="$attrs" @handleChangeEye="handleChangeEye">
    <div v-bind="$attrs" style="font-size: 12px"
      >{{ $attrs.type === 'money' ? '¥ ' : '' }}<span class="money">{{ $attrs.modelValue }}</span>
    </div>
  </yf-eye>
</template>
<script setup lang="ts">
  interface IYfEmits {
    (e: 'handleChangeEye', params: string): void
  }

  const emit = defineEmits<IYfEmits>()
  const handleChangeEye = (type: string) => {
    emit('handleChangeEye', type)
  }
</script>

<style scoped lang="scss"></style>
