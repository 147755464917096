import { createApp } from 'vue'
import StoreDialog from './index.vue'

let $vm: any
let $unmount: () => void

const initInstance = () => {
  const app = createApp(StoreDialog)
  const container = document.createElement('div')
  $vm = app.mount(container)
  document.body.appendChild(container)
  console.log($vm)
  $unmount = () => {
    $vm = null
    app.unmount()
    document.body.removeChild(container)
  }
}

function createInstance(
  resolve: (data: any) => void,
  reject: (data: any) => void,
  options: any = {}
) {
  if (!$vm) initInstance()
  for (const i in options) {
    $vm[i] = options[i]
  }
  $vm.resolve = (data: any) => {
    resolve(data)
    $unmount()
  }
  $vm.reject = (data: any) => {
    reject(data)
    $unmount()
  }
  $vm.show()
}

export function ShowStoreDialog(options: any = {}) {
  return new Promise((resolve: (data: any) => void, reject: (data: any) => void) => {
    createInstance(resolve, reject, options)
  })
}
