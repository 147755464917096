import { post, get } from '@/utils/request'
import { EOfflineType, EPayType, IExtendObj } from '@/types'

const gate = '/api/oc'

// 订单批量确认收货
export function ocBatchReceive(data: IExtendObj = {}) {
  return post<any>(`${gate}/order_info/v1/batch_confirm_receipt`, data)
}

// 订单确认收货
export function ocReceive(data: IExtendObj = {}) {
  return post<any>(`${gate}/order_info/v1/confirm_receipt`, data)
}

/**
 * 批量提交
 * @param data
 * @returns
 */
export function batchSubmitOrderInfo(data: IExtendObj = {}) {
  return post<any>(`${gate}/order_info/v2/batch_submit_by_self`, data)
}

/**
 * 查询批发订单列表
 * @param data
 * @returns
 */
export function queryOrderInfoList(data: IExtendObj = {}) {
  return post<any>(`${gate}/order_info/v1/list_for_pc`, data)
}
/**
 * 根据批次号获取批次订单基础信息
 * @param data
 * @returns
 */
export function queryBatchOrderInforByBatchNo(data: IExtendObj = {}) {
  return post<any>(`${gate}/order_info/v1/order_detail_by_batch_no`, data)
}

/**
 * 查询oc购物车数量
 * @param data
 * @returns
 */
export function getSubscribeQty(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/get_order_subscribe_total_sku_qty`, data)
}

/**
 * 查询购物车列表
 * @param data
 * @returns
 */
export function queryOcSubscribeList(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/query_order_subscribe_list_for_pc`, data)
}

/**
 * 编辑购物车
 * @param data
 * @returns
 */
export function editOcSubscribe(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/update_order_subscribe`, data, {
    isUnErrorMessage: true
  })
}

/**
 * 购物车删除
 * @param data
 * @returns
 */
export function deleteOcSubscribe(data: IExtendObj = {}) {
  return post<any>(`${gate}/oc_order_subscribe/v2/delete_order_subscribe_for_pc`, data)
}

// 校验客户订单号
export function selectByCustomerOrderCode(data: { customerOrderCode: string }) {
  return post<any>(`${gate}/order_info/v1/select_by_customer_order_code`, {
    jsonData: data
  })
}

// 校验库存
export function verifyStorage(data: IExtendObj = {}) {
  return post<any>(
    `${gate}/oc_order_subscribe/v1/verify_storage_qty`,
    {
      jsonData: data
    },
    {
      isUnBatchErrorMessage: true
    }
  )
}

// 保存/保存并提交
export function saveAndSubmit(data: IExtendObj, isUnBatchErrorMessage: boolean = false) {
  return post<any>(`${gate}/order_info/v2/place_order_by_self`, data, {
    isUnErrorMessage: true,
    isUnBatchErrorMessage
  })
}

/*
  订单详情
*/
interface IQueryDetail {
  orderInfoId: string | number
  isPc: boolean
}

export function queryDetail(data: IQueryDetail) {
  return post<any>(
    `${gate}/order_info/v1/select_order_info_details?orderInfoId=${data.orderInfoId}&isPc=${data.isPc}`,
    data
  )
}

/**
 *订单复制新增 获取获取详情
 * @param data
 * @returns
 */
export function getOrderCopyDetail(data: IExtendObj) {
  return get<IExtendObj[]>(
    `${gate}/order_info/v1/query_order_by_copy_for_pc?orderInfoId=${data.orderInfoId}&isPc=${data.isPc}`,
    {}
  )
}

interface IBatchParams {
  jsonData: IExtendObj
}

/**
 * 查询批次号
 * @param data
 * @returns
 */
export function selectBatchNo(data?: IBatchParams) {
  return post<number[]>(`${gate}/order_info/v1/select_batch_no`, data)
}

/**
 * 批量撤销提交
 * @param data
 * @returns
 */
type TBatchCancelOrder = {
  orderInfoIds: (number | string)[]
}

export function cancelBatchOrder(data: TBatchCancelOrder) {
  return post<any>(`${gate}/order_info/v2/batch_cancel_submit_by_self`, { jsonData: data })
}

interface IPayCreateParams {
  ocOrderInfoIdList: number[]
  payType: EPayType
  isMini: boolean
  offlineType?: EOfflineType
  fileUrlList?: string[]
  payCodeList?: string[]
}

/**
 * 发起支付
 * @param data
 * @returns
 */
export function createPayOrder(jsonData: IPayCreateParams) {
  return post<{ ocOrderInfoPaymentInfoId: number; content: string; payValidTime: number }>(
    `${gate}/payment_info/v1/create_pay_order`,
    {
      jsonData
    },
    {
      isUnErrorMessage: true
    }
  )
}

export interface IPayDetail {
  /**
   * 订单编号
   */
  tradeOrderNoList: string[]
  /**
   * 批次号
   */
  batchNoList: string[]
  /**
   * 订单价格
   */
  orderPrice: number
  /**
   * 剩余时间毫秒
   */
  payValidTime: number
  ocOrderInfoIdList: number[]
  /**
   * 支付凭证
   */
  fileUrlList: string[]
  /**
   * 支付流水
   */
  payCodeList: string[]
}

/**
 * 查询订单支付详情
 * @param data
 * @returns
 */
export function selectPaymentOrder(data: {
  jsonData: { ocOrderInfoIdList?: number[]; batchNoList?: string[] }
}) {
  return post<IPayDetail>(`${gate}/order_info/v1/select_payment_order`, data)
}

interface IBatchParams {
  jsonData: IExtendObj
}

/**
 * 轮询支付
 * @param data
 * @returns
 */
export function selectPayOrder(dataId: number) {
  return post<{ status: number; payMoney: number; tradeStateDesc: string }>(
    `${gate}/payment_info/v1/select_pay_order`,
    {
      dataId
    }
  )
}

/**
 * 删除
 * @param {*} data
 * @returns
 */
export function batchDetele(data: IExtendObj) {
  return post<any>(`${gate}/order_info/v1/delete_batch_order_info`, data)
}

/**
 * 取消订单
 * @returns Promise
 */
export function orderCancel(data: IExtendObj) {
  return post<any>(`${gate}/order_info/v1/cancel_order_info`, data)
}

/**
 * 一键加入购物车
 * @returns Promise
 */
export function saveOrderSubscribe(data: IExtendObj) {
  return post<any>(`${gate}/oc_order_subscribe/v2/save_order_subscribe`, data)
}

/**
 *订单撤销线下支付
 * @param ids
 * @returns
 */
export function cancelPay(ids: (number | string)[]) {
  return post<IExtendObj>(`${gate}/payment_info/v1/batch_repeal_off_line_order`, {
    jsonData: { ids }
  })
}

/**
 * 批发发票列表
 * @param data
 * @returns
 */
export function selectOrderInvoiceList(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/invoice/v1/select_invoice_order_page`, data)
}

/**
 * 开票校验
 */
export function checkInvoice(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/invoice/v1/check_invoice`, data)
}

/**
 * 再次购买校验
 */
export function checkAddCart(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_order_info_items/v1/order_info_buy_again_check`, data)
}

/**
 * 再次加入购物车
 */
export function addOcCart(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_order_subscribe/v1/order_info_buy_again`, data)
}

/**
 * 发票模块可开票列表数量统计
 */
export function applyInvoiceTotal(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/invoice/v1/select_invoice_order_page_sum`, data)
}

/**

 * 查询售后列表
 * @param data
 * @returns
 */
export function selectSalesPageOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_sales_return/v1/select_sales_page`, data)
}

/**
 * 查询售后列表数量
 * @param data
 * @returns
 */
export function selectSalesTableCountOrder(data: IExtendObj) {
  return post<string[]>(`${gate}/oc_sales_return/v1/select_sales_table_count`, data)
}

/**
 * 退款申请-前置查询
 * @param data
 * @returns
 */
export function getOrderRefundInfo(data: { jsonData: { orderInfoIds: number[] } }) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/query_order_for_refund`, data)
}

/**
 * 提交退款申请（单个）
 * @param data
 * @returns
 */
export function submitRefundOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/submit_refund_order_single`, data, {
    isUnErrorMessage: true
  })
}

/**
 * 获取退款申请详情
 * @param data
 * @returns
 */
export function getOrderRefundDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/query_refund_order_detail`, data)
}

/**
 * 获取售后商品明细/赠品明细
 * @param data
 * @returns
 */
export function getOrderAfterSaleProductAndGift(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_order_info_items/v1/select_goods_and_gift_by_order_id`, data)
}

/**
 * 提交售后申请
 * @param data
 * @returns
 */
export function submitAfterSaleOrder(data: IExtendObj) {
  return post<any>(`${gate}/oc_sales_return/v1/submit_sales_return_for_pc`, data)
}

/**
 * 提交售后申请保存前校验
 * @param data
 * @returns
 */
export function submitAfterSaleCheckOrder(data: IExtendObj) {
  return post<any>(`${gate}/oc_sales_return/v1/submit_sales_return_check`, data)
}

/**
 * 获取关联售后
 * @param data
 * @returns
 */
export function selectOrderSalesReturnList(data: IExtendObj) {
  return post<IExtendObj[]>(`${gate}/oc_sales_return/v1/select_sales_return_list`, data)
}

/**
 * 获取售后详情
 * @param data
 * @returns
 */
export function getOrderSalesReturnDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_sales_return/v1/query_sale_return_detail_for_pc`, data)
}

/**
 * 取消售后
 * @param data
 * @returns
 */
export function cancelSalesReturnOrder(params: IExtendObj) {
  return get<IExtendObj>(
    `${gate}/oc_sales_return/v1/cancel_sales_return?salesReturnId=${params.salesReturnId}`,
    {}
  )
}

/**
 * 更新退货物流
 * @param data
 * @returns
 */
export function updateLogisticsOrder(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_sales_return/v1/update_sales_return_logistics`, data)
}

/**
 * 获取售后详情物流轨迹
 * @param data
 * @returns
 */
export function getLogisticsOrderList(data: IExtendObj) {
  return post<IExtendObj[]>(`${gate}/oc_order_info_logistics/v1/query_logistics_trajectory`, data)
}

/**
 * 提交退款申请（批量）
 * @param data
 * @returns
 */
export function submitRefundOrderBatch(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v2/submit_refund_order_for_pc`, data)
}

/**
 * 获取批量仅退款提交后退款单展示详情
 */
export function batchOrderRefundDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/oc_refund_order_info/v1/query_batch_result`, data)
}

/**
 * 获取积分信息
 */
export function selectIntegral(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/order_info/v1/select_integral_capital`, data)
}
