import axios, { AxiosRequestConfig, Method } from 'axios'
// import Utils from '@yf-web/utils'
import CryptoJS from 'crypto-js'
import useUserStore from '@/store/user'
import useCustomerStore from '@/store/customer'
import router from '@/router'
import BatchErrorHandler from '@/utils/batchErrorHandler'
import { ShowBatchTaskProgress } from '@/components/BatchTaskProgress/index.js'
import { ElMessage } from 'element-plus'
import { ShowLoginDialog } from '@/components/LoginComponent/LoginDialogModel'

let loginFailureTime: number | null = null
const toLogin = () => {
  ElMessage({
    message: '登录失效，请重新登录',
    type: 'error',
    dangerouslyUseHTMLString: true
  })
  const userStore = useUserStore()
  const useCustomer = useCustomerStore()
  userStore.removeUser()
  useCustomer.removeCustomerInfo()
  location.href = '/homePage'
}

interface IResponse<T> {
  [x: string]: any

  code: string
  content: T
  desc: string
}

interface IRequestParams {
  url: string
  method: Method
  data?: any
  params?: any
  config?: AxiosRequestConfig
}

const service = axios.create({
  //   baseURL: BASE_API,
  timeout: 60000, // request timeout
  validateStatus: function (status) {
    return status >= 200 && status < 304 // default
  }
})

service.interceptors.request.use((config) => {
  config.headers['loginFrom'] = 'yuncai_mall'
  if (!config.isUnToken) {
    const userStore = useUserStore()
    const token = userStore.user?.token
    if (token) {
      config.headers['token'] = token
    } else {
      // toLogin()
    }
  }
  if (!config.isUnLoading) {
    // $toast.loading({
    //     message: "加载中...",
    //     forbidClick: true,
    // });
  }
  //request拦截逻辑
  return config
})
service.interceptors.response.use(
  async (response) => {
    const { status, data, config } = response
    // if (!config.isUnLoading) {
    //     $toast.clear();
    // }
    if (status === 200 && data.code === '000') {
      if (data.encrypt) {
        console.group('encrypt:接口:', response.config.url)
        console.log(`出参解密前:`, data)
        // const jsonString = Utils.Secret.decrypt(data.content, '!@#*&^1650bg@#%!', {
        //   mode: CryptoJS.mode.ECB,
        //   padding: CryptoJS.pad.Pkcs7
        // })
        const encryptedHexStr = CryptoJS.enc.Base64.parse(data.content)
        const str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
        const decrypt = CryptoJS.AES.decrypt(str, CryptoJS.enc.Utf8.parse('!@#*&^1650bg@#%!'), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        })
        const jsonString = CryptoJS.enc.Utf8.stringify(decrypt)
        try {
          data.content = JSON.parse(jsonString)
        } catch (error) {
          data.content = jsonString
        }
        console.log(`出参解密后:`, data)
        console.groupEnd()
      }
      if (data.taskId) {
        return ShowBatchTaskProgress({ response: data })
      }
      if (data.content || data.content === 0) {
        // 操作批量的单例
        const batchErrorHandler = BatchErrorHandler.create(data.content)
        // isFree 字段代表如果是操作批量接口，是否不显示消息弹框
        const isFree = !!config.isUnBatchErrorMessage
        // 是批量的操作接口的 做如下操作
        if (batchErrorHandler.getIsBatchPost() && !isFree) {
          // 判定是否需要抛出异常的接口
          let except = false
          // 判定是单条还是多条
          if (batchErrorHandler.getIsBatch()) {
            // 判定 多条的时候 是否需要抛出异常
            except = batchErrorHandler.getBatchIsException()
            // 判定 多条的时候 是否需要做弹框显示
            if (batchErrorHandler.getBatchShowErrorFlag()) {
              // 判定是否需要二次弹框
              if (batchErrorHandler.getIsConfirm()) {
                try {
                  await batchErrorHandler.showBatchError()
                  return { ...data, isBatchConfirm: true }
                } catch (error) {
                  return Promise.reject({
                    message: data.desc,
                    code: data.code,
                    content: data.content,
                    isBatchCancel: true
                  })
                }
              }
              batchErrorHandler.showBatchError()
            }
          } else {
            // 判定 单条的时候 是否需要抛出异常
            except = batchErrorHandler.getSingleIsException()
            // 判定 单条的时候 是否需要做弹框显示
            except && batchErrorHandler.showSingleError()
          }
          // 将接口响应为异常
          if (except) {
            return Promise.reject({ message: data.desc, code: data.code, content: data.content })
          }
        }
        // 非批量操作的时候 正常返回
        return data
      } else {
        return data
      }
      return data
    } else if (
      [401, 403].includes(status) ||
      (status === 200 && ['10010', '09990'].includes(data.code))
    ) {
      console.log(12, data.code)
      // ElMessage({
      //   message: data.desc || '登录失效',
      //   type: 'error',
      //   dangerouslyUseHTMLString: true
      // })
      // const userStore = useUserStore()
      toLogin()
    } else if ([404, 405].includes(status)) {
      ElMessage({
        message: data.desc || '请求失败',
        type: 'error',
        dangerouslyUseHTMLString: true
      })
    } else if (!config.isUnErrorMessage) {
      ElMessage({
        message: data.desc,
        type: 'error',
        dangerouslyUseHTMLString: true
      })
    }
    throw data
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      // if (!loginFailureTime || Date.now() - loginFailureTime > 4000) {
      //   loginFailureTime = Date.now()
      //   ElMessage.error('登录失效')
      // }
      // const userStore = useUserStore()
      console.log('error.response', error.response)
      toLogin()
    } else {
      const data = { desc: '网络错误！' }
      ElMessage.error(data.desc)
    }
    throw error.response
  }
)

export async function request<T>(
  url: string,
  method: Method,
  data?: any,
  params?: any,
  config?: AxiosRequestConfig
) {
  try {
    const item: IRequestParams = {
      url,
      method,
      ...config
    }
    if (data) {
      item.data = data
    }
    if (params) {
      item.params = params
    }
    const res = await service(item)
    return res as unknown as IResponse<T>
  } catch (error) {
    throw error
  }
}

export function get<T>(url: string, params: any, config?: AxiosRequestConfig) {
  return request<T>(url, 'GET', undefined, params, config)
}

export function post<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return request<T>(url, 'POST', data, undefined, config)
}

export function postAxios<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return request<T>(url, 'POST', data, undefined, config)
}
