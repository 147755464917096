<template>
  <el-popover
    v-bind="$attrs"
    :placement="props.placement"
    :teleported="teleported"
    :width="props.width"
    :show-arrow="true"
    :popper-class="`yf-popper ${props.className}`"
    @hide="hide"
    @show="show"
  >
    <template #reference>
      <slot name="btn" />
    </template>
    <slot name="content" />
  </el-popover>
</template>

<script setup lang="ts">
  interface IProps {
    visible?: boolean
    placement?: string
    teleported?: boolean
    width?: string | number
    className?: string
  }

  interface IEmits {
    (e: 'hide'): void
    (e: 'show'): void
  }

  const props = withDefaults(defineProps<IProps>(), {
    placement: 'bottom-start',
    teleported: true,
    width: 'auto'
  })
  const emit = defineEmits<IEmits>()
  const show = () => {
    console.log('show')
    emit('show')
  }
  const hide = () => {
    console.log('hide')
    emit('hide')
  }
</script>

<style lang="scss">
  .yf-popper {
    border: 1px solid #d08f96 !important;
    box-shadow: 0px 2px 4px 0px rgba(221, 221, 221, 0.5) !important;

    .el-popper__arrow {
      &:before {
        border: 1px solid #d08f96 !important;
      }
    }
  }
</style>
