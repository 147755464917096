<template>
  <div class="authentication-type-dialog">
    <el-dialog
      v-model="props.visible"
      title="请选择认证类型"
      width="300px"
      :close-on-click-modal="false"
      :beforeClose="beforeClose"
    >
      <div class="item" :class="authType === 'user' ? 'active' : ''" @click="handleChange('user')">
        <svg-icon
          icon-class="register-user"
          size="30px"
          :style="authType === 'user' ? { color: '#fff' } : {}"
        />
        <span class="text">个人用户</span>
      </div>
      <div
        class="item"
        :class="authType === 'company' ? 'active' : ''"
        @click="handleChange('company')"
      >
        <svg-icon
          icon-class="register-comp"
          size="30px"
          :style="authType === 'company' ? { color: '#fff' } : {}"
        />
        <span class="text">企业/个体工商用户</span>
      </div>
      <template #footer>
        <span class="contract-dialog-footer">
          <el-button type="primary" @click="toConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { IExtendObj } from '@/types'
  import { getCompanyVerify } from '@/api/cus'
  import useUserStore from '@/store/user'
  import { ShowAuthenticCodeDialog } from '@/components/authenticationCodeDialog/authenticationCodeDialog'

  interface IProps {
    visible: boolean
    type: number
  }

  interface IEmits {
    (e: 'resolve', params: IExtendObj): void

    (e: 'reject', params: IExtendObj): void

    (e: 'success'): void
  }

  const props = defineProps<IProps>()
  const emit = defineEmits<IEmits>()
  const authType = ref<string>('user')
  const userStore = useUserStore()

  const resolve = (data: IExtendObj) => {
    emit('resolve', data)
  }

  const reject = (data: IExtendObj) => {
    emit('reject', data)
  }

  const beforeClose = () => {
    emit('success')
    resolve({})
  }
  const handleChange = (type: string) => {
    authType.value = type
  }
  const toConfirm = async () => {
    console.log(authType.value)
    try {
      if (authType.value === 'company') {
        const { content } = await getCompanyVerify()
        emit('success')
        window.open(content, '_blank')
      } else {
        await ShowAuthenticCodeDialog({ type: props.type })
      }
      resolve({ to: 'toConfirm' })
    } catch (e) {}
  }
</script>

<style scoped lang="scss">
  .authentication-type-dialog {
    .item {
      display: flex;
      align-items: center;
      padding: 10px 8px;
      width: 180px;
      font-size: 14px;
      margin: 0 auto 10px;
      background-color: #efefef;
      height: 50px;
      border-radius: 4px;
      cursor: pointer;

      .text {
        margin-left: 8px;
      }
    }

    .active {
      background-color: #a21f2d;

      .text {
        color: #fff;
      }
    }
  }
</style>
